import BarGraph from "./BarGraph";
import { useState, useEffect } from "react";
import SelectField from "./SelectField";
import { useAppData } from "../context/appData";
import { useUserDetails } from "../api/userAuth";
import { useAppTranslations } from "../api/translations";
import { useUserLanguage } from "../context/userLanguage";

export default function ExercisesByCategory() {
  const { data: userData, isLoading, isError } = useUserDetails();
  const { language } = useUserLanguage();
  const { data: appTranslation } = useAppTranslations();

  const { data: categoryClick } = useAppData("categoryClick");
  const [exercisesByCategory, setExercisesByCategory] = useState({
    male: true,
    female: false,
    category: [],
  });
  const [categories, setCategories] = useState({});
  const [data, setData] = useState({ male: {}, female: {} });
  useEffect(() => {
    if (isLoading) return;
    if (isError) return;
    if (!userData) return;
    let temp = {};
    Object.keys(userData?.user?.group?.female ?? {}).map((item) => {
      Object.keys(userData?.user?.group?.female[item]).map((ele) => {
        temp = {
          ...temp,
          [ele]: ele,
        };
      });
    });
    setCategories(temp);
  }, [isLoading, isError, userData]);
  useEffect(() => {
    let temp = {},
      temp1 = {};

    Object.keys(categoryClick?.["male"] ?? {}).length > 0 &&
      Object.keys(categoryClick?.["male"] ?? {})?.map((category) => {
        if (
          exercisesByCategory?.category.length === 0 ||
          exercisesByCategory?.category?.includes(category)
        ) {
          temp = {
            ...temp,
            [category]: categoryClick?.["male"][category],
          };
        }
      });
    Object.keys(categoryClick?.["female"] ?? {}).length > 0 &&
      Object.keys(categoryClick?.["female"] ?? {}).map((category) => {
        if (
          exercisesByCategory?.category.length === 0 ||
          exercisesByCategory?.category?.includes(category)
        ) {
          temp1 = {
            ...temp1,
            [category]: temp1[category]
              ? temp1[category] + categoryClick?.["female"][category]
              : categoryClick?.["female"][category],
          };
        }
      });
    setData((pre) => ({ ...pre, male: temp, female: temp1 }));
  }, [exercisesByCategory]);
  return (
    <>
      <div className="w-full px-6 pb-6">
        <p className="text-white text-lg font-medium">
          {appTranslation?.translations?.adminPage?.numOfExerciseByCategory?.[language] ??
            "Num. of Exercises by Category"}
        </p>
        <div className="flex items-center justify-start gap-4 mt-2">
          <SelectField
            value={exercisesByCategory?.category}
            setValue={(value) => {
              if (exercisesByCategory?.category.includes(value)) {
                setExercisesByCategory((pre) => ({
                  ...pre,
                  category: [
                    ...pre.category.filter((category) => category !== value),
                  ],
                }));
              } else {
                setExercisesByCategory((pre) => ({
                  ...pre,
                  category: [...pre.category, value],
                }));
              }
            }}
            className="w-48"
          >
            {Object.keys(categories).map((category) => {
              return <option value={category} dir={language == 'en' ? 'ltr' : 'rtl'}>{appTranslation?.translations?.equipment?.[category.toLowerCase()]?.[language]}</option>;
            })}
          </SelectField>
        </div>
      </div>
      <div className="w-full px-6 pb-6">
        <BarGraph male={data.male} female={data.female} equipment />
      </div>
    </>
  );
}
