import Image from "./Image";
import Accordion from "./Accordian";

export default function SlideComponent({
  translations,
  language,
  element,
  setOpenedExercise,
}) {
  return (
    <div className="flex gap-y-1 gap-x-1 flex-wrap w-[90%] h-fit cursor-pointer mb-2">
      <div className="flex justify-between w-full">
          <Image
          className='!w-[49%] !h-[100%]'
            src={
              element.custom
                ? process.env.REACT_APP_API_URL + element.gifTitles[0]
                : `${process.env.REACT_APP_VIDEOS_URL}${element.gifTitles[0]}.mp4`
            }
            setOpenedExercise={setOpenedExercise}
          />
          <Image
          className='!w-[49%] !h-[100%]'
            src={
              element.custom
                ? process.env.REACT_APP_API_URL + element.gifTitles[1]
                : `${process.env.REACT_APP_VIDEOS_URL}${element.gifTitles[1]}.mp4`
            }
            setOpenedExercise={setOpenedExercise}
          />
      </div>
      <div className="text-white flex justify-between items-center w-full ">
        <div className="text-white w-full">
          <Accordion
            dir={language === "he" ? "rtl" : "ltr"}
            title={element?.title?.[language]}
            content="content"
          >
            <ol
              className="list-decimal tex-base text-white px-4 h-32 custom-scroll"
              dir={language === "he" ? "rtl" : "ltr"}
            >
              {element?.instructions?.[language].map((ele, index) => (
                <li key={index} className="text-base">{ele}</li>
              ))}
            </ol>
            <div
              className="flex text-white tex-base justify-between items-center mt-4 w-full"
              dir={language === "he" ? "rtl" : "ltr"}
            >
              <span className="flex-1">{element?.difficulty?.[language]}</span>
              &nbsp;
              <span className="flex-1">
                2 X {translations?.sets?.[language] ?? "Sets"}
              </span>
              <span className="flex-1">
                10 X {translations?.reps?.[language] ?? "Reps"}
              </span>
              <span className="ml-12">
                {translations?.need_help_btn?.[language] ?? "Need Help?"}
              </span>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
