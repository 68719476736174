import FemaleModel from "../components/FemaleModel";
import MaleModel from "../components/MaleModel";
import { Canvas } from "@react-three/fiber";
import { Stage, PresentationControls } from "@react-three/drei";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../components/AppLayout";
import BackIcon from "../assets/BackIcon";
import { useUserLanguage } from "../context/userLanguage";
import CrossIcon from "../assets/CrossIcon";
import CallIcon from "../assets/CallIcon";
import MailIcon from "../assets/MailIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SlideComponent from "../components/SlideComponent";
import { useAppData } from "../context/appData";
import { useEffect } from "react";
import OutsideClickHandler from "../components/OutsideClickHandler";
import useIdle from "../hooks/useIdle";
import { useAppTranslations } from "../api/translations";
import { useUserDetails } from "../api/userAuth";

export default function Exercise() {
  const navigate = useNavigate();
  const { data: userData, isLoading, isError } = useUserDetails();

  useEffect(() => {
    if (isLoading) return;
    if (isError) return;
    if (
      userData?.user?.active === false ||
      userData?.user?.group === "" ||
      userData?.user?.group === null
    ) {
      navigate("/dashboard");
    }
  }, [isLoading, isError, userData]);

  const {
    data: appTranslation,
    isLoading: appTranslationLoading,
    isError: appTranslationError,
  } = useAppTranslations();

  const isIdle = useIdle({ timeToIdle: 120000, inactivityEvents: [] });
  const [popup, setPopup] = useState("");
  const { language } = useUserLanguage();
  const { bodypart, gender } = useParams();
  const [openedExercise, setOpenedExercise] = useState("");
  const [Appstate, setAppstate] = useState({
    name: "",
    exercises: [],
  });
  const { setData } = useAppData();
  function toggleactions(item, name) {
    setData((prev) => {
      return {
        ...prev,
        categoryClick: {
          ...(prev.categoryClick ?? {}),
          [gender]: {
            ...(prev.categoryClick?.[gender] ?? {}),
            [name]: prev.categoryClick?.[gender]?.[name]
              ? (prev.categoryClick[gender][name] ?? 0) + 1
              : 1,
          },
        },
      };
    });

    setAppstate((prev) => ({
      ...prev,
      name: name,
      exercises: Object.values(item).filter((item) => item?.active),
    }));
  }
  useEffect(() => {
    if (isIdle) {
      navigate("/dashboard");
    }
  }, [isIdle]);

  return (
    <>
      <AppLayout>
        <div
          className="flex flex-col justify-between"
          style={{ height: "calc(100vh - 155px)" }}
        >
          <div className="flex">
            <div className="w-[20%]">
              <Canvas dpr={[1, 2]} shadows camera={{ fov: 65, zoom: 2}}>
                <PresentationControls
                  global
                  rotation={[0, 0, 0]}
                  polar={[0, 0]}
                  azimuth={[-Infinity, Infinity]}
                >
                  <Stage environment={null}>
                    {gender === "male" ? (
                      <MaleModel bodypart={bodypart} />
                    ) : (
                      <FemaleModel bodypart={bodypart} />
                    )}
                  </Stage>
                </PresentationControls>
              </Canvas>
            </div>

            <div className="flex flex-col gap-y-4 w-1/3">
              <div className="text-white text-3xl font-bold">
                {appTranslation?.translations?.muscles?.[bodypart][language] ??
                  bodypart}
              </div>
              <div className="flex flex-wrap w-full cursor-pointer h-[65vh] custom-scroll">
                {Object.keys(
                  userData?.user?.group?.[gender]?.[bodypart] ?? {}
                ).map((element, index) => (
                  <div
                    onClick={() => {
                      toggleactions(
                        userData?.user?.group?.[gender]?.[bodypart]?.[element],
                        element
                      );
                    }}
                    key={index}
                    className={`flex w-[49%] items-center justify-center gap-4 flex-col px-4 xl:px-12 py-2 xl:py-6 bg-[#0D3475] text-white mt-1 xl:mt-2 ${
                      Appstate.name === element
                        ? "bg-[#5F93E9]"
                        : "bg-[#0D3475]"
                    }
                    ${index % 2 === 0 ? "mr-1 xl:mr-2" : ""}
                    `}
                  >
                    <img
                      className="w-[110px] h-[72px] object-cover filter-none"
                      src={`/${element}.webp`}
                      alt={element}
                      fetchpriority="eager"
                    />
                    <span className="text-right text-lg">
                      {
                        userData?.user?.group?.[gender]?.[bodypart]?.[
                          element
                        ]?.[
                          Object.keys(
                            userData?.user?.group?.[gender]?.[bodypart]?.[
                              element
                            ]
                          )?.[0]
                        ]?.equipment[language]
                      }
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {Appstate.exercises.length > 0 && (
              <Swiper
                slidesPerView={1}
                slidesPerColumn={3}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="!flex-1 !ml-4 xl:!ml-16 !h-max !pb-8"
              >
                {Appstate.exercises.map((element, index) =>
                  index % 3 === 0 ? (
                    <SwiperSlide
                      className="!w-full !h-max !max-h-[72vh] !overflow-y-auto custom-scroll "
                      key={index}
                    >
                      {Appstate.exercises[index] && (
                        <SlideComponent
                          element={Appstate.exercises[index]}
                          language={language}
                          translations={
                            appTranslation?.translations?.galleryPage
                          }
                          setOpenedExercise={setOpenedExercise}
                        />
                      )}
                      {Appstate.exercises[index + 1] && (
                        <SlideComponent
                          element={Appstate.exercises[index + 1]}
                          language={language}
                          translations={
                            appTranslation?.translations?.galleryPage
                          }
                          setOpenedExercise={setOpenedExercise}
                        />
                      )}
                      {Appstate.exercises[index + 2] && (
                        <SlideComponent
                          element={Appstate.exercises[index + 2]}
                          language={language}
                          translations={
                            appTranslation?.translations?.galleryPage
                          }
                          setOpenedExercise={setOpenedExercise}
                        />
                      )}
                    </SwiperSlide>
                  ) : null
                )}
              </Swiper>
            )}
          </div>
          <div className="flex items-center justify-between mt-4 px-4">
            <div className="bottom-6 left-6">
              <BackIcon onClick={() => navigate(-1)} />
            </div>
            <div>
              <p
                dir={language === "he" ? "rtl" : "ltr"}
                className="text-white text-base font-light"
              >
                {appTranslation?.translations?.galleryPage?.bottom_text?.[
                  language
                ] ?? "By using you are consenting to Terms of Use"}
                &nbsp;
                <a
                  className="underline"
                  href={
                    appTranslation?.translations?.galleryPage?.terms?.[language]
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {appTranslation?.translations?.general?.terms_btn?.[
                    language
                  ] ?? "Terms of Use"}
                </a>
              </p>
            </div>
            <div className=" flex items-center justify-end">
              <div className="relative mr-10">
                {popup === "help" && (
                  <div className="absolute bottom-full right-0 bg-[#061A3D] p-2 w-max z-10 shadow-md">
                    <div className="flex justify-end">
                      <CrossIcon
                        className={"w-5 h-5 cursor-pointer"}
                        onClick={() => {
                          setPopup("");
                        }}
                      />
                    </div>
                    <p className="text-white text-base p-4 font-light">
                      {appTranslation?.translations?.galleryPage?.help?.[
                        language
                      ] ?? "Lorem ipsum dolor sit amet, consectetur a"}
                    </p>
                  </div>
                )}
                <p
                  className="text-white font-light cursor-pointer"
                  onClick={() => {
                    setPopup("help");
                  }}
                >
                  {appTranslation?.translations?.galleryPage?.help_btn?.[
                    language
                  ] ?? "Help"}
                </p>
              </div>
              <div className="relative">
                {popup === "contact" && (
                  <div className="absolute bottom-full right-0 bg-[#061A3D] p-2 shadow-md px-4 pb-3 z-10 w-max">
                    <div className="flex justify-end">
                      <CrossIcon
                        className={"w-5 h-5 cursor-pointer"}
                        onClick={() => {
                          setPopup("");
                        }}
                      />
                    </div>
                    <a
                      className="flex flex-col justify-center items-center gap-1 w-full"
                      href={`tel:${
                        appTranslation?.translations?.galleryPage?.contact_us
                          ?.phone ?? "03-777-7777"
                      }`}
                    >
                      <CallIcon />
                      <p className="text-white text-base font-light">
                        {appTranslation?.translations?.galleryPage?.contact_us
                          ?.phone ?? "03-777-7777"}
                      </p>
                    </a>
                    <a
                      className="flex flex-col justify-center items-center gap-1 w-full mt-4"
                      href={`mailto:${
                        appTranslation?.translations?.galleryPage?.contact_us
                          ?.email ?? "samplemail@gmail.com"
                      }`}
                    >
                      <MailIcon />
                      <p className="text-white text-base font-light">
                        {appTranslation?.translations?.galleryPage?.contact_us
                          ?.email ?? "samplemail@gmail.com"}
                      </p>
                    </a>
                  </div>
                )}
                <p
                  onClick={() => {
                    setPopup("contact");
                  }}
                  className="text-white font-light cursor-pointer"
                >
                  {appTranslation?.translations?.galleryPage?.contact_us_btn?.[
                    language
                  ] ?? "Contact Us"}
                </p>
              </div>
            </div>
          </div>
        </div>
        {openedExercise && (
          <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex justify-center items-center z-10">
            <OutsideClickHandler
              className="relative w-full h-full"
              style={{
                maxHeight: "calc(100vh - 200px)",
                maxWidth: "calc(100vw - 100px)",
              }}
              onClickOutside={() => {
                setOpenedExercise("");
              }}
            >
              <div className="absolute -top-10 -right-10 p-4">
                <CrossIcon
                  className="w-6 h-6"
                  onClick={() => {
                    setOpenedExercise("");
                  }}
                />
              </div>
              <video
                autoPlay
                loop
                muted
                className="w-full h-full object-contain relative"
              >
                <source src={openedExercise} type="video/mp4" />
              </video>
            </OutsideClickHandler>
          </div>
        )}
      </AppLayout>
    </>
  );
}
