export default function CheckIcon({fill, className}) {
    return (
      <>
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className ?? ''}
        >
          <path
            d="M4.21997 9.25C4.02113 9.24982 3.83046 9.17072 3.68994 9.03003L0.249939 5.59009C0.179182 5.52114 0.122931 5.43868 0.0845337 5.34766C0.0461362 5.25663 0.0263672 5.15885 0.0263672 5.06006C0.0263672 4.96127 0.0461362 4.86349 0.0845337 4.77246C0.122931 4.68143 0.179182 4.59897 0.249939 4.53003C0.390564 4.38958 0.581218 4.31055 0.779968 4.31055C0.978719 4.31055 1.16931 4.38958 1.30994 4.53003L4.21997 7.43994L10.6899 0.969971C10.8306 0.82952 11.0212 0.750732 11.22 0.750732C11.4187 0.750732 11.6093 0.82952 11.7499 0.969971C11.8904 1.1106 11.9693 1.30125 11.9693 1.5C11.9693 1.69875 11.8904 1.8894 11.7499 2.03003L4.74994 9.03003C4.60942 9.17072 4.41882 9.24982 4.21997 9.25Z"
            fill={fill ?? "#4C5866"}
          />
        </svg>
      </>
    );
  }
  