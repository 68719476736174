import { useState } from "react";
import CrossIcon from "../assets/CrossIcon";
import CallIcon from "../assets/CallIcon";
import MailIcon from "../assets/MailIcon";
import { useAppTranslations } from "../api/translations";
import { useUserLanguage } from "../context/userLanguage";

export default function DashboardFooter() {
  const [popup, setPopup] = useState("");
  const { language } = useUserLanguage();
  const {
    data: appTranslation,
    isLoading: appTranslationLoading,
    isError: appTranslationError,
  } = useAppTranslations();

  return (
    <>
      <div className="fixed bottom-6 right-6 flex items-center justify-end">
        <div className="relative mr-10">
          {popup === "help" && (
            <div className="absolute bottom-full right-0 bg-[#061A3D] p-2 shadow-md px-4 pb-3 z-10 w-max">
              <div className="flex justify-end">
                <CrossIcon
                  onClick={() => {
                    setPopup("");
                  }}
                  className="cursor-pointer !w-5 !h-5"
                />
              </div>
              <p className="text-white text-base p-4 font-light">
                {appTranslation?.translations?.general?.help?.[language] ??
                  "Lorem ipsum dolor sit amet, consectetur a"}
              </p>
            </div>
          )}
          <p
            className="text-white font-light text-lg cursor-pointer"
            onClick={() => {
              setPopup("help");
            }}
          >
            {appTranslation?.translations?.homePage?.help_title?.[language] ??
              "Help"}
          </p>
        </div>
        <div className="relative">
          {popup === "contact" && (
            <div className="absolute bottom-full right-0 bg-[#061A3D] p-2 shadow-md px-4 pb-3 z-10 w-max">
              <div className="flex justify-end">
                <CrossIcon
                  onClick={() => {
                    setPopup("");
                  }}
                  className="cursor-pointer w-5 h-5"
                />
              </div>
              <a
                className="flex flex-col justify-center items-center gap-1 w-full"
                href={`tel:${
                  appTranslation?.translations?.general?.contact_us?.phone ??
                  "03-777-7777"
                }`}
              >
                <CallIcon />
                <p className="text-white text-base font-light">
                  {appTranslation?.translations?.general?.contact_us?.phone ??
                    "03-777-7777"}
                </p>
              </a>
              <a
                className="flex flex-col justify-center items-center gap-1 w-full mt-4"
                href={`mailto:${
                  appTranslation?.translations?.general?.contact_us?.email ??
                  "samplemail@gmail.com"
                }`}
              >
                <MailIcon />
                <p className="text-white text-base font-light">
                  {appTranslation?.translations?.general?.contact_us?.email ??
                    "samplemail@gmail.com"}
                </p>
              </a>
            </div>
          )}
          <p
            onClick={() => {
              setPopup("contact");
            }}
            className="text-white font-light text-lg cursor-pointer"
          >
            {appTranslation?.translations?.general?.contact_us?.[language] ??
              "Contact Us"}
          </p>
        </div>
      </div>
    </>
  );
}
