import { useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Stage, PresentationControls } from "@react-three/drei";
import { Link } from "react-router-dom";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useUserGender } from "../context/userGender";
import AppLayout from "../components/AppLayout";
import { useUserLanguage } from "../context/userLanguage";
import RotateIcon from "../assets/RotateIcon";
import { useAppData } from "../context/appData";
import { useAppTranslations } from "../api/translations";
import { useUserDetails } from "../api/userAuth";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useJoinGroupRequest, useLogout } from "../hooks/user";
import DashboardFooter from "../section/DashboardFooter";
import MaleModel from "../components/MaleModel";
import FemaleModel from "../components/FemaleModel"

const Dashboard = () => {
  const { data: userData, isLoading } = useUserDetails();

  const {
    data: appTranslation,
  } = useAppTranslations();

  const { data: userStat, setData } = useAppData();
  const { language } = useUserLanguage();
  const { gender, setGender } = useUserGender();
  const [selectedPart, setSelectedPart] = useState("");

  const logoutMutaion = useLogout();
  const groupJoinRequestMutation = useJoinGroupRequest();

  return (
    <>
      {(logoutMutaion.isLoading ||
        groupJoinRequestMutation.isLoading ||
        isLoading) && <Loader />}
      {isLoading ? (
        ""
      ) : !userData?.user?.group?.name ? (
        <Modal
          title="You are not assigned to any group, please ask your coach to assign you to a group"
          confirmText="Ask Coach"
          cancelText="Logout"
          onConfirm={() => {
            groupJoinRequestMutation.mutate({
              id: userData?.user?._id,
            });
          }}
          onCancel={() => {
            logoutMutaion.mutate({ userStat });
          }}
        />
      ) : (
        ""
      )}
      <AppLayout>
        <div className="text-white mt-4 flex flex-col gap-y-3 justify-center items-center">
          <p
            className="text-3xl font-semibold"
            dir={language === "he" ? "rtl" : "ltr"}
          >
            {appTranslation?.translations?.homePage?.choose_muscle?.[
              language
            ] ?? "Choose Muscle"}
          </p>
          <span className="text-xl" dir={language === "he" ? "rtl" : "ltr"}>
            {appTranslation?.translations?.muscles?.rotate_draging?.[
              language
            ] ?? "Rotate by draging"}
          </span>
        </div>
        <div className="flex items-center justify-evenly w-full mx-auto relative -mt-24">
          {selectedPart && (
            <div className="absolute top-[60%] z-10">
              <Link
                to={`/exercise/${gender}/${selectedPart}`}
                onClick={() => {
                  setData((pre) => ({
                    ...pre,
                    genderClick: {
                      ...(pre?.genderClick ?? {}),
                      [gender]: {
                        ...(pre?.genderClick?.[gender] ?? {}),
                        clicks: [
                          ...(pre?.genderClick
                            ? pre?.genderClick[gender]?.clicks ?? []
                            : []),
                          {
                            muscle: selectedPart,
                            date: new Date(),
                          },
                        ],
                      },
                    },
                  }));
                }}
              >
                <button
                  className="whitespace-nowrap flex justify-center items-center text-white text-xl font-light px-6 py-1 border border-[#00FF00] rounded-[10px]	"
                  dir={language === "he" ? "rtl" : "ltr"}
                >
                  {appTranslation?.translations?.homePage?.go_to_btn?.[
                    language
                  ] ?? "Go to"}
                  &nbsp;
                  <span className="font-semibold">
                    {
                      appTranslation?.translations?.muscles?.[selectedPart]?.[
                        language
                      ]
                    }
                  </span>
                  <DoubleRightOutlined
                    className={`text-sm ${
                      language === "en" ? "ml-1" : "rotate-180 mr-1"
                    }`}
                  />
                </button>
              </Link>
            </div>
          )}
          <div className="relative flex justify-center">
            <Canvas
              dpr={[1, 2]}
              shadows
              camera={{ fov: 65, zoom: 1.15}}
              className="!w-[50vw] !h-[calc(100vh-145px)]"
            >
              <PresentationControls
                global
                rotation={[0, 0, 0]}
                polar={[0, 0]}
                azimuth={[-Infinity, Infinity]}
              >
                <Stage environment={null}>
                  <FemaleModel
                    setSelectedPart={setSelectedPart}
                    setGender={setGender}
                  />
                </Stage>
              </PresentationControls>
            </Canvas>
            <div className="absolute bottom-[22%] md:-bottom-7 z-[-1]">
              <RotateIcon
                fill={gender === "female" ? "white" : "#384151"}
                className="w-3/4 xl:w-full"
              />
            </div>
          </div>

          <div className=" relative flex justify-center">
            <Canvas
              dpr={[1, 2]}
              shadows
              camera={{ fov: 65, zoom: 1.15 }}
              className="!w-[50vw] !h-[calc(100vh-145px)]"
            >
              <PresentationControls
                global
                rotation={[0, 0, 0]}
                polar={[0, 0]}
                azimuth={[-Infinity, Infinity]}
              >
                <mesh>
                  <Stage environment={null}>
                    <MaleModel
                      setSelectedPart={setSelectedPart}
                      setGender={setGender}
                    />
                  </Stage>
                </mesh>
              </PresentationControls>
            </Canvas>
            <div className="absolute bottom-[22%] md:-bottom-7 z-[-1]">
              <RotateIcon
                fill={gender === "male" ? "white" : "#384151"}
                className="w-3/4 xl:w-full"
              />
            </div>
          </div>
        </div>
        <DashboardFooter />
      </AppLayout>
    </>
  );
};

export default Dashboard;
