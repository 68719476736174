import Chart from "react-apexcharts";
import { useAppTranslations } from "../api/translations";
import { useUserLanguage } from "../context/userLanguage";

export default function BarChart({ male, female, equipment }) {
  const { language } = useUserLanguage();
  const { data: appTranslation, isLoading } = useAppTranslations();

  const state = {
    series: [
      {
        name:
          appTranslation?.translations?.adminPage?.male?.[language] ?? "Male",
        data: Object.values(male ?? {}),
      },
      {
        name:
          appTranslation?.translations?.adminPage?.female?.[language] ??
          "Female",
        data: Object.values(female ?? {}),
      },
    ],
    options: {
      annotations: {
        points: [
          {
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
              },
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },

      grid: {
        show: true,
        borderColor: "#fff",
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          ...new Set([
            ...Object.keys(male ?? {}).map(
              (item) =>
                appTranslation?.translations?.[equipment ? 'equipment' : 'muscles']?.[equipment ? item.toLowerCase() : item]?.[language] ??
                item
            ),
            ...Object.keys(female ?? {}).map(
              (item) =>
                appTranslation?.translations?.[equipment ? 'equipment' : 'muscles']?.[equipment ? item.toLowerCase() : item]?.[language] ??
                item
            ),
          ]),
        ],
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            if (value >= 1000000000) {
              return Math.floor(value / 1000) + " B";
            } else if (value >= 1000000) {
              return Math.floor(value / 1000) + " M";
            } else if (value >= 1000) {
              return Math.floor(value / 1000) + " K";
            } else {
              return value;
            }
          },
          style: {
            colors: "#fff",
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        labels: {
          colors: "#fff",
          useSeriesColors: false,
        },
      },
    },
  };

  return Object.keys(male ?? {}).length > 0 ||
    Object.keys(female ?? {}).length > 0 ? (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      height={400}
    />
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      <p className="text-white font-light text-sm">
        {appTranslation?.translations?.adminPage?.noData?.[language] ??
          "No data"}
      </p>
    </div>
  );
}
