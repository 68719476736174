export default function RotateIcon({ className, fill }) {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="175.186"
    height="62.405"
    viewBox="0 0 175.186 62.405"
    className={className ?? ""}
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectangle_25"
          data-name="Rectangle 25"
          width="175.186"
          height="62.405"
          fill={fill ?? "#fff"}
        />
      </clipPath>
    </defs>
    <g id="Group_21" data-name="Group 21" clipPath="url(#clipPath)">
      <path
        id="Path_226"
        data-name="Path 226"
        d="M60.75,45.7l6.678-4.736c-5.178-.29-7.373-.489-12.519-1.1-3.981-.471-7.95-1.026-11.9-1.717A125.8,125.8,0,0,1,21.44,32.586a49.266,49.266,0,0,1-9.892-4.844,18.193,18.193,0,0,1-4.027-3.535,7.6,7.6,0,0,1-1.894-4.616A7.613,7.613,0,0,1,7.5,14.954a18.233,18.233,0,0,1,4.016-3.568A49.36,49.36,0,0,1,21.4,6.475c3.026-1.139,6.12-2.095,9.25-2.939V.317a100.369,100.369,0,0,0-10.6,2.574A54.9,54.9,0,0,0,8.867,7.485a22.3,22.3,0,0,0-5.4,4.231A11.406,11.406,0,0,0,2.241,13.25,11.053,11.053,0,0,0,1.17,15.077a11.582,11.582,0,0,0-.795,2.139A15.084,15.084,0,0,0,0,19.591a15.435,15.435,0,0,0,.16,2.42,12.346,12.346,0,0,0,.6,2.263,12.27,12.27,0,0,0,.914,2,12.98,12.98,0,0,0,1.1,1.741,25.269,25.269,0,0,0,5.1,5.137,55.594,55.594,0,0,0,10.946,6.387,111.333,111.333,0,0,0,11.238,4.249c3.772,1.192,7.55,2.3,11.367,3.145C46.95,48.209,52.5,49.2,58.068,49.966c4.479.614,6,.811,10.494,1.134Z"
        transform="translate(0 -0.168)"
        fill={fill ?? "#fff"}
      />
      <path
        id="Path_227"
        data-name="Path 227"
        d="M271.335,17.039a11.6,11.6,0,0,0-.8-2.146,11.077,11.077,0,0,0-1.075-1.833,11.477,11.477,0,0,0-1.229-1.539,22.317,22.317,0,0,0-5.412-4.237A54.366,54.366,0,0,0,251.631,2.7,102.091,102.091,0,0,0,240.387,0V3.19a101.548,101.548,0,0,1,9.885,3.118,49.343,49.343,0,0,1,9.882,4.911,18.2,18.2,0,0,1,4.015,3.567,7.614,7.614,0,0,1,1.869,4.636,7.6,7.6,0,0,1-1.894,4.615,18.19,18.19,0,0,1-4.026,3.535,49.274,49.274,0,0,1-9.892,4.844,125.757,125.757,0,0,1-21.571,5.564A258.157,258.157,0,0,1,184.1,41.514c-.81,0-1.62-.009-2.43-.015l.157,10.257c.758.01,1.516.024,2.273.026a207.921,207.921,0,0,0,46.149-4.972c3.818-.853,7.6-1.931,11.373-3.137a111.3,111.3,0,0,0,11.241-4.255,60.041,60.041,0,0,0,5.535-2.808,45.909,45.909,0,0,0,5.422-3.581,25.332,25.332,0,0,0,5.107-5.15,13.127,13.127,0,0,0,1.1-1.746,12.321,12.321,0,0,0,.917-2.011,12.375,12.375,0,0,0,.605-2.272,15.5,15.5,0,0,0,.16-2.429,15.15,15.15,0,0,0-.377-2.384"
        transform="translate(-96.527)"
        fill={fill ?? "#fff"}
      />
      <path
        id="Path_228"
        data-name="Path 228"
        d="M176.593,63,148.843,79.44l27.749,16.44Z"
        transform="translate(-79.086 -33.474)"
        fill={fill ?? "#fff"}
      />
    </g>
  </svg>;
}
