import { useParams, useNavigate } from "react-router-dom";
import DateTimeBlock from "../components/DateTimeBlock";
import { useUserLanguage } from "../context/userLanguage";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "../api/userAuth";
import Loader from "../components/Loader";
import { useNotification } from "../AppNotification/NotificationProvider";

export default function ResetPassword() {
  const notification = useNotification();
  const navigate = useNavigate();
  const { language, setLanguage } = useUserLanguage();
  const { id, token } = useParams();
  const [inputValues, setInputValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const passwordResetMutation = useMutation(resetPassword,
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          notification({
            type: "SUCCESS",
            message: "Password reset successfully",
          });
          setInputValues({
            ...inputValues,
            loading: false,
          });
          navigate("/login");
        } else {
          notification({
            type: "ERROR",
            message: 'verification link expired',
          });
        }
      },
      onError: (error) => {
        notification({
          type: "ERROR",
          message: "Something went wrong, try again",
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputValues.password.trim() || !inputValues.confirmPassword.trim())
      return notification({
        type: "ERROR",
        message: "Please fill all the fields",
      });

    if (inputValues.password.trim() !== inputValues.confirmPassword.trim()) {
      notification({
        type: "ERROR",
        message: "Password and confirm password should be same",
      });
    } else {
      passwordResetMutation.mutate({ password: inputValues.password.trim(), id, token });
    }
  };

  return (
    <div className="bg-[url('../public/login.png')] bg-no-repeat bg-cover h-screen w-screen">
      {passwordResetMutation.isLoading ? <Loader /> : ""}
      <div className="flex w-[90%] mx-auto justify-between items-center">
        <DateTimeBlock />
        <div className="text-white flex gap-x-3">
          <span
            className={`py-2 cursor-pointer
            ${language === "en" ? "text-white" : "text-[#707070]"}
            `}
            onClick={() => {
              setLanguage("en");
              localStorage.setItem("language", "en");
            }}
          >
            ENG
          </span>
          <span
            className={`border-l-2 pl-2 py-2 border-[#006FA1] cursor-pointer ${
              language === "he" ? "text-white" : "text-[#707070]"
            }
            `}
            onClick={() => {
              setLanguage("he");
              localStorage.setItem("language", "he");
            }}
          >
            עבר
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col justify-center items-center pt-6 w-full max-w-lg ml-auto">
          <div className="mb-3">
            <img
              className="w-42 h-28 object-cover"
              src="/logoVertical.webp"
              alt="No image"
              srcSet=""
              fetchpriority="eager"
            />
          </div>
          <div
            className="w-full max-w-sm flex flex-col justify-center items-center"
            dir={language === "he" ? "rtl" : "ltr"}
          >
            <div className="flex justify-center items-center mt-2 w-full">
              <input
                className="bg-transparent appearance-none border placeholder:bold border-[#00B9FF] rounded-[10px] py-3 px-4 w-full placeholder-white text-white leading-tight focus:outline-none focus:bg-transparent  focus:border-[#00B9FF]"
                type="password"
                placeholder={"Password"}
                onChange={(e) =>
                  setInputValues({ ...inputValues, password: e.target.value })
                }
                value={inputValues.password}
              />
            </div>
            <div className="flex justify-center items-center mt-6 w-full">
              <input
                className="bg-transparent appearance-none border placeholder:bold border-[#00B9FF] rounded-[10px] py-3 px-4 w-full  placeholder-white text-white leading-tight focus:outline-none focus:bg-transparent  focus:border-[#00B9FF]"
                type="password"
                placeholder={"Confirm Password"}
                onChange={(e) =>
                  setInputValues({
                    ...inputValues,
                    confirmPassword: e.target.value,
                  })
                }
                value={inputValues.confirmPassword}
              />
            </div>
            <button
              className="bg-[#00B9FF] mt-8 text-sm font-bold  text-white py-[14px] w-full rounded-[10px]"
              type="submit"
            >
              {"Reset Password"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
