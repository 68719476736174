import Pagelogin from "./pages/Pagelogin";
import { useUserAuth } from "./context/authProvider";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import ResetPassword from "./pages/ResetPassword";
import ForgetPassword from "./pages/ForgetPassword";
import Loader from "./components/Loader";
import Dashboard from "./pages/Dashboard";
import Exercise from "./pages/Exercise";
import Admin from "./pages/Admin";

export default function App() {
  const { user, admin } = useUserAuth();

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={user ? <Navigate to="/dashboard" /> : <Pagelogin />}
        />
        <Route
          exact
          path="/dashboard"
          element={user ? <Suspense fallback={<Loader />}><Dashboard /></Suspense> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/exercise/:gender/:bodypart"
          element={user ? <Exercise /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/admin"
          element={
            user ? (
              admin ? (
                <Admin />
              ) : (
                <Navigate to="/dashboard" />
              )
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token/:id" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}
