export default function CrossIcon({ circle = true, fill, className, ...props }) {
  return (
    circle ? (
      <svg
        id="x-mark"
        xmlns="http://www.w3.org/2000/svg"
        width="11.612"
        height="11.612"
        viewBox="0 0 11.612 11.612"
        className={`cursor-pointer ${className ?? ""}`}
        {...props}
      >
        <path
          id="Path_333"
          data-name="Path 333"
          d="M5.806,11.612a5.806,5.806,0,1,1,5.806-5.806A5.812,5.812,0,0,1,5.806,11.612Zm0-10.782a4.976,4.976,0,1,0,4.976,4.976A4.982,4.982,0,0,0,5.806.829Zm0,0"
          fill={fill ?? "#b7b7b7"}
        />
        <path
          id="Path_334"
          data-name="Path 334"
          d="M150.849,151.265a.413.413,0,0,1-.293-.122L146.408,147a.415.415,0,0,1,.586-.586l4.147,4.147a.415.415,0,0,1-.293.708Zm0,0"
          transform="translate(-142.969 -142.971)"
          fill={fill ?? "#b7b7b7"}
        />
        <path
          id="Path_335"
          data-name="Path 335"
          d="M146.7,151.263a.415.415,0,0,1-.293-.708l4.147-4.147a.415.415,0,0,1,.586.586l-4.147,4.147A.413.413,0,0,1,146.7,151.263Zm0,0"
          transform="translate(-142.968 -142.969)"
          fill={fill ?? "#b7b7b7"}
        />
      </svg>
    ) : (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={`cursor-pointer ${className ?? ""}`}
        {...props}
      >
        <path d="M18 3.27375L14.7263 0L9 5.72625L3.27375 0L0 3.27375L5.72625 9L0 14.7263L3.27375 18L9 12.2737L14.7263 18L18 14.7263L12.2737 9L18 3.27375Z" fill={fill ?? "#4ECB71"} />
      </svg>
    )
  );
}
