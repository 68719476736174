import { useState } from "react";
import { useUserAuth } from "../context/authProvider";
import { useUserLanguage } from "../context/userLanguage";
import DateTimeBlock from "../components/DateTimeBlock";
import CrossIcon from "../assets/CrossIcon";
import MailIcon from "../assets/MailIcon";
import CallIcon from "../assets/CallIcon";
import Loader from "../components/Loader";
import { useAppTranslations } from "../api/translations";
import { userLogin, switchDevice } from "../api/userAuth";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "../AppNotification/NotificationProvider";
import Modal from "../components/Modal";
import { Link } from "react-router-dom";

export default function Pagelogin() {
  const notification = useNotification();
  const {
    data: appTranslation,
    isLoading: appTranslationLoading,
    isError: appTranslationError,
  } = useAppTranslations();
  const [popup, setPopup] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(null);
  const { language, setLanguage } = useUserLanguage();
  const { setUser } = useUserAuth();
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
    error: "",
  });

  function handleLogin(e) {
    e.preventDefault();
    loginMutaion.mutate({
      email: inputValues.email,
      password: inputValues.password,
    });
  }

  const loginMutaion = useMutation((data) => userLogin(data), {
    onSuccess: (data) => {
      if (!data.user) {
        notification({
          type: "ERROR",
          message: data.response.data.msg,
        });
        return;
      }
      if (data?.user?.active === false) {
        notification({
          type: "ERROR",
          message:
            "Your account is not active, please contact the administrator",
        });
        return;
      }
      if (data?.user?.loggedIn) {
        setUserLoggedIn(data?.user);
        return;
      }
      localStorage.clear();
      localStorage.setItem("token", data?.token);
      localStorage.setItem(
        "teamer",
        JSON.stringify(data?.user?.userStat ?? {})
      );
      localStorage.setItem("sessionID", data?.user?.sessionID);
      setUser(data.token);
    },
    onError: (error) => {
      notification({
        type: "ERROR",
        message: "Wrong password, try again",
      });
    },
  });

  const switchDeviceMutation = useMutation((data) => switchDevice(data), {
    onSuccess: (data) => {
      if (!data.user) {
        notification({
          type: "ERROR",
          message: data.response.data.msg,
        });
        return;
      }
      if (data?.user?.active === false) {
        notification({
          type: "ERROR",
          message:
            "Your account is not active, please contact the administrator",
        });
        return;
      }
      localStorage.clear();
      localStorage.setItem("token", data?.token);
      localStorage.setItem(
        "teamer",
        JSON.stringify(data?.user?.userStat ?? {})
      );
      localStorage.setItem("sessionID", data?.user?.sessionID);
      setUser(data.token);
    },

    onError: (error) => {
      notification({
        type: "ERROR",
        message: "Something went wrong!, try again",
      });
    },
  });

  return (
    <>
      {loginMutaion.isLoading || appTranslationLoading ? <Loader /> : ""}
      {userLoggedIn?.loggedIn && (
        <Modal
          title="Your account is already logged in on another device"
          cancelText="Cancel"
          onCancel={() => {
            setUserLoggedIn(null);
          }}
          confirmText="Continue Here"
          onConfirm={() => {
            switchDeviceMutation.mutate({
              id: userLoggedIn?._id,
              sessionID: new Date().getTime(),
            });
          }}
        />
      )}
      <div className="bg-[url('../public/login.png')] bg-no-repeat bg-cover h-screen w-screen">
        <div className="flex w-[90%] mx-auto justify-between items-center">
          <DateTimeBlock />
          <div className="text-white flex gap-x-3">
            <span
              className={`py-2 cursor-pointer
            ${language === "en" ? "text-white" : "text-[#707070]"}
            `}
              onClick={() => {
                setLanguage("en");
                localStorage.setItem("language", "en");
              }}
            >
              ENG
            </span>
            <span
              className={`border-l-2 pl-2 py-2 border-[#006FA1] cursor-pointer ${
                language === "he" ? "text-white" : "text-[#707070]"
              }
            `}
              onClick={() => {
                setLanguage("he");
                localStorage.setItem("language", "he");
              }}
            >
              עבר
            </span>
          </div>
        </div>
        <form onSubmit={handleLogin}>
          <div className=" flex flex-col justify-center items-center pt-6 w-full max-w-lg ml-auto">
            <div className="mb-3">
              <img
                className="w-42 h-28 object-cover"
                src="./logoVertical.webp"
                alt="No image"
                srcSet=""
                fetchpriority="eager"
              />
            </div>
            <div
              className="w-full max-w-sm flex flex-col justify-center items-center"
              dir={language === "he" ? "rtl" : "ltr"}
            >
              <div className="flex justify-center items-center mt-2 w-full">
                <input
                  className="bg-transparent appearance-none border placeholder:bold border-[#00B9FF] rounded-[10px] py-3 px-4 w-full placeholder-white text-white leading-tight focus:outline-none focus:bg-transparent  focus:border-[#00B9FF]"
                  type="text"
                  placeholder={
                    appTranslation?.translations?.loginPage?.username_field?.[
                      language
                    ] ?? "Username"
                  }
                  onChange={(e) =>
                    setInputValues({ ...inputValues, email: e.target.value })
                  }
                  value={inputValues.email}
                />
              </div>
              <div className="flex justify-center items-center mt-6 w-full">
                <input
                  className="bg-transparent appearance-none border placeholder:bold border-[#00B9FF] rounded-[10px] py-3 px-4 w-full  placeholder-white text-white leading-tight focus:outline-none focus:bg-transparent  focus:border-[#00B9FF]"
                  type="password"
                  placeholder={
                    appTranslation?.translations?.loginPage?.password_field?.[
                      language
                    ] ?? "Password"
                  }
                  onChange={(e) =>
                    setInputValues({ ...inputValues, password: e.target.value })
                  }
                  value={inputValues.password}
                />
              </div>
              <Link className="w-full text-right text-white mt-2" to="forget-password">
                {appTranslation?.translations?.loginPage?.forgot_password?.[
                  language
                ] ?? "Forgot Password?"}
              </Link>
              {inputValues.error && (
                <div className="flex text-sm items-center justify-start mt-3 w-full text-[#FF0000]">
                  {inputValues.error}
                </div>
              )}
              <button
                className="bg-[#00B9FF] mt-8 text-sm font-bold  text-white py-[14px] px-14 rounded-[10px]"
                type="submit"
              >
                {appTranslation?.translations?.loginPage?.login_btn?.[
                  language
                ] ?? "Login"}
              </button>
              <div className="flex justify-center items-center mt-20 mr-4 text-white text-[14px] font-extralight ">
                {appTranslation?.translations?.loginPage?.need_help?.[
                  language
                ] ?? "Need Help?"}
                &nbsp;
                <div className="relative">
                  {popup ? (
                    <div className="absolute bottom-full right-0 bg-[#061A3D] p-2 w-[135px] shadow-md pb-3">
                      <div className="flex justify-end">
                        <CrossIcon
                          onClick={() => {
                            setPopup(false);
                          }}
                        />
                      </div>
                      <a
                        className="flex flex-col justify-center items-center gap-1 w-full"
                        href={`tel:${
                          appTranslation?.translations?.loginPage?.contact_us
                            ?.phone ?? "03-777-7777"
                        }`}
                      >
                        <CallIcon />
                        <p className="text-white text-[10px] font-light">
                          {appTranslation?.translations?.loginPage?.contact_us
                            ?.phone ?? "03-777-7777"}
                        </p>
                      </a>
                      <a
                        className="flex flex-col justify-center items-center gap-1 w-full mt-4"
                        href={`mailto:${
                          appTranslation?.translations?.loginPage?.contact_us
                            ?.email ?? "samplemail@gmail.com"
                        }`}
                      >
                        <MailIcon />
                        <p className="text-white text-[10px] font-light">
                          {appTranslation?.translations?.loginPage?.contact_us
                            ?.email ?? "samplemail@gmail.com"}
                        </p>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <span
                    className="px-2 underline"
                    onClick={() => {
                      setPopup(true);
                    }}
                  >
                    {appTranslation?.translations?.loginPage?.contact_us?.[
                      language
                    ] ?? "Contact Us"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
