import { createContext, useState, useContext, useEffect } from "react";
export const AppDataContext = createContext();

export default function AppData({ children }) {
  const [data, setData] = useState(
    localStorage.getItem("teamer")
      ? JSON.parse(localStorage.getItem("teamer"))
      : {}
  );
  useEffect(() => {
    localStorage.setItem("teamer", JSON.stringify(data));
  }, [data]);
  return (
    <AppDataContext.Provider value={{ data, setData }}>
      {children}
    </AppDataContext.Provider>
  );
}

export function useAppData(module, secondModule) {
  let { data, setData } = useContext(AppDataContext);
  return { data: data ? secondModule ? data[module][secondModule] ?? data : data[module] ?? data : {}, setData };
}
