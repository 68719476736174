import { createContext, useState, useContext } from "react";
export const UserAuthContext = createContext();

export default function UserAuth({ children }) {
  const [user, setUser] = useState(localStorage.getItem("token") ?? null);
  const [admin, setAdmin] = useState(sessionStorage.getItem("admin") ?? null);

  return (
    <UserAuthContext.Provider value={{ user, setUser, admin, setAdmin }}>
      {children}
    </UserAuthContext.Provider>
  );
}
export function useUserAuth() {
  return useContext(UserAuthContext);
}
