import React, { useEffect, useState } from "react";

export default function Image({ src, className, setOpenedExercise, ...props}) {
  
  return (
    <>
      <div className={`w-[144px] xl:w-[245px] h-[126px] ${className ?? ""}`} key={src}>
        <video
          autoPlay
          loop
          muted
          className="w-full h-full object-cover"
          onClick={() => setOpenedExercise && setOpenedExercise(src)}
          {...props}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </>
  );
}
