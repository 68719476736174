import { useState } from "react";
import OutsideClickHandler from "./OutsideClickHandler";
import CheckIcon from "../assets/CheckIcon";
import FormFieldIcon from "../assets/FormFieldIcon";

export default function SelectField({
  disabled = false,
  selectedIcon = true,
  ...props
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className={`flex flex-col gap-[7px] mb-2 w-full ${props.className ?? ""
          } `}
      >
        <div className="flex w-full">
          <div
            className="select-field border-gray-500 border bg-white relative"
            onClick={() => {
              if (disabled) return;
              setOpen(() => true);
            }}
          >
            {(disabled || !props.value) ? (
              <div className="absolute inset-0 bg-gray-400 bg-opacity-50 rounded"></div>
            ) : (
              ""
            )}
            <div className="flex items-center justify-between w-full relative">
              <h2 className="text-sm font-light ">
                {props.value
                  ? Array.isArray(props.value)
                    ? `${props.value?.length} selected`
                    : props.value
                  : props.placeholder ?? "Select"}
              </h2>
              <FormFieldIcon fill="black" />
            </div>
            {
              <OutsideClickHandler
                onClickOutside={() => {
                  setOpen(false);
                }}
                className={`transition-all ${open ? "select-dropdown" : "hide-dropdown"
                  }`}
              >
                <div className="custom-scroll">
                  {props.children?.length > 0 ? (
                    props.children.map((child) => (
                      <div
                        className={`select-dropdown-item relative`}
                        dir={child.props.dir ?? "ltr"}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setValue(child.props.value);
                          !selectedIcon && setOpen(false);
                        }}
                      >
                        {
                          child.props.disabled ? (<div className="absolute h-full w-full left-0 top-0 z-50 bg-black bg-opacity-20 rounded"></div>) : ''
                        }
                        {child.props.children}
                        {selectedIcon
                          ? Array.isArray(props.value)
                            ? props.value.includes(child.props.value) && (
                              <CheckIcon fill="#64C1AB" />
                            )
                            : props.value == child.props.value && (
                              <CheckIcon fill="#64C1AB" className="!w-2" />
                            )
                          : null}
                      </div>
                    ))
                  ) : (
                    <div className="select-dropdown-item w-full">No Data</div>
                  )}
                </div>
              </OutsideClickHandler>
            }
          </div>
        </div>
      </div>
    </>
  );
}
