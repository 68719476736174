export default function AddIcon({ className, onClick }) {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ""}
      onClick={onClick}
    >
      <path
        d="M14.85 24.75H18.15V18.15H24.75V14.85H18.15V8.25H14.85V14.85H8.25V18.15H14.85V24.75ZM16.5 33C14.2175 33 12.0725 32.5666 10.065 31.6998C8.0575 30.833 6.31125 29.6576 4.82625 28.1737C3.34125 26.6887 2.1659 24.9425 1.3002 22.935C0.4345 20.9275 0.0011 18.7825 0 16.5C0 14.2175 0.4334 12.0725 1.3002 10.065C2.167 8.0575 3.34235 6.31125 4.82625 4.82625C6.31125 3.34125 8.0575 2.1659 10.065 1.3002C12.0725 0.4345 14.2175 0.0011 16.5 0C18.7825 0 20.9275 0.4334 22.935 1.3002C24.9425 2.167 26.6887 3.34235 28.1737 4.82625C29.6587 6.31125 30.8346 8.0575 31.7014 10.065C32.5682 12.0725 33.0011 14.2175 33 16.5C33 18.7825 32.5666 20.9275 31.6998 22.935C30.833 24.9425 29.6576 26.6887 28.1737 28.1737C26.6887 29.6587 24.9425 30.8346 22.935 31.7014C20.9275 32.5682 18.7825 33.0011 16.5 33Z"
        fill="#CFE741"
      />
    </svg>
  );
}
