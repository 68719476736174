import { useMutation } from "@tanstack/react-query";
import { groupJoinRequest, userLogout } from "../api/userAuth";
import { useUserAuth } from "../context/authProvider";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../AppNotification/NotificationProvider";

export function useLogout() {
  const navigate = useNavigate();
  const { setUser, setAdmin } = useUserAuth();
  return useMutation(userLogout, {
    onSuccess: (data) => {
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      setAdmin(null);
      navigate("/");
    },
    onError: (error) => {
      sessionStorage.clear();
      localStorage.removeItem("token");
      setUser(null);
      setAdmin(null);
      navigate("/");
    },
  });
}

export function useJoinGroupRequest() {
    const { notification } = useNotification();
  return useMutation(groupJoinRequest, {
    onSuccess: (data) => {
      notification({
        type: "SUCCESS",
        message: "Request sent to your coach, please wait for approval",
      });
    },
    onError: (error) => {
      notification({
        type: "ERROR",
        message: "Something went wrong, please try again later",
      });
    },
  });
}
