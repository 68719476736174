import { createContext, useState, useContext } from "react";

export const UserLanguageContext = createContext();

export default function UserLanguage({ children }) {
  const [language, setLanguage] = useState(localStorage.getItem("language") ?? "en");
  return (
    <UserLanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </UserLanguageContext.Provider>
  );
}

export function useUserLanguage() {
  return useContext(UserLanguageContext);
}
