import { createContext, useState, useContext } from "react";

export const UserGenderContext = createContext();

export default function UserGender({ children }) {
  const [gender, setGender] = useState("");
  return (
    <UserGenderContext.Provider value={{ gender, setGender }}>
      {children}
    </UserGenderContext.Provider>
  );
}

export function useUserGender() {
  return useContext(UserGenderContext);
}
