import * as THREE from "three";
import model from "../assets/female.fbx";
import { forwardRef, memo, useEffect } from "react";
import { useFBX } from "@react-three/drei";
import { useUserGender } from "../context/userGender";

export const femalePartModel = {
  B001: "Abdominals",
  A001: "Chest",
  C001: "Biceps",
  E002: "Calves",
  D001: "Quads",
  G008: "Shoulders",
  G002: "Lower back",
  G004: "Glutes",
  G005: "Hamstrings",
  G006: "Triceps",
  G009: "Traps (mid-back)",
  G007: "Forearms",
  G012: "Obliques",
  G013: "Traps",
  G015: "Lats",
};
export const PartModel = {
  Abdominals: {
    value: "B001",
    angle: 0,
  },
  Chest: {
    value: "A001",
    angle: 0,
  },
  Biceps: {
    value: "C001",
    angle: 0,
  },
  Calves: {
    value: "E002",
    angle: 0.2,
  },
  Quads: {
    value: "D001",
    angle: 0,
  },
  Shoulders: {
    value: "G008",
    angle: 0,
  },
  "Lower back": {
    value: "G002",
    angle: 0.2,
  },
  Glutes: {
    value: "G004",
    angle: 0.3,
  },
  Hamstrings: {
    value: "G005",
    angle: 0.2,
  },
  Triceps: {
    value: "G006",
    angle: 0.1,
  },
  "Traps (mid-back)": {
    value: "G009",
    angle: 0.2,
  },
  Forearms: {
    value: "G007",
    angle: 0.2,
  },
  Obliques: {
    value: "G012",
    angle: 0,
  },
  Traps: {
    value: "G013",
    angle: 0.1,
  },
  Lats: {
    value: "G015",
    angle: 0.1,
  },
};
const FemaleModel = forwardRef(
  ({ setSelectedPart, bodypart, multiple, ...props }, ref) => {
    const { gender, setGender } = useUserGender();
    let obj = useFBX(model);
    function handleClick(event) {
      event.stopPropagation();
      if (!setGender || !setSelectedPart) return;
      setGender("female");
      setSelectedPart(femalePartModel[event.object.name]);
      for (const key in femalePartModel) {
        if (key == event.object.name) {
          obj.getObjectByName(key).material.color.setHex(0x00ff00);
        } else {
          try {
            if (!multiple) {
              obj.getObjectByName(key).material.color.setHex(0xe7e7e7);
            }
          } catch (e) {}
        }
      }
    }
    if (bodypart) {
      try {
        obj
          .getObjectByName(PartModel[bodypart]?.value)
          .material.color.setHex(0x00ff00);
      } catch (e) {}
      try {
        if (PartModel[bodypart]?.angle !== 0) {
          obj.rotateOnAxis(
            new THREE.Vector3(0, 1, 0),
            PartModel[bodypart]?.angle
          );
        }
      } catch (e) {}
    }
    function RemoveColorFemale(muscle) {
      for (const key in femalePartModel) {
        if (muscle) {
          if (muscle == femalePartModel[key]) {
            obj.getObjectByName(key).material.color.setHex(0xe7e7e7);
          }
        } else {
          try {
            obj.getObjectByName(key).material.color.setHex(0xe7e7e7);
          } catch (e) {}
        }
      }
    }
    useEffect(() => {
      if (gender && gender !== "female") {
        RemoveColorFemale();
      }
      if (ref) ref.current = RemoveColorFemale;
    }, [gender]);

    useEffect(() => {
      return () => {
        RemoveColorFemale();
      };
    }, []);
    return (
      <primitive object={obj} onClick={(e) => handleClick(e)} dispose={null} />
    );
  }
);

export default memo(FemaleModel);
