import Chart from "react-apexcharts";
import { useAppTranslations } from "../api/translations";
import { useUserLanguage } from "../context/userLanguage";

export default function PieChart({ data, options }) {
  const { language } = useUserLanguage();
  const { data: appTranslation, isLoading } = useAppTranslations();
  let temp = [
    data?.male?.clicks?.filter((item) => {
      let cmpDate = null;
      if (item.date.seconds) {
        cmpDate = new Date(item.date.nanoseconds + item.date.seconds * 1000);
      } else {
        cmpDate = new Date(item.date);
      }
      return (
        cmpDate.getFullYear() == options.year &&
        cmpDate.getMonth() + 1 == options.month
      );
    }).length ?? 0,
    data?.female?.clicks?.filter((item) => {
      let cmpDate = null;
      if (item.date.seconds) {
        cmpDate = new Date(item.date.nanoseconds + item.date.seconds * 1000);
      } else {
        cmpDate = new Date(item.date);
      }
      return (
        cmpDate.getFullYear() == options.year &&
        cmpDate.getMonth() + 1 == options.month
      );
    }).length ?? 0,
  ];
  const state = {
    series: temp,
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [
        appTranslation?.translations?.adminPage?.male?.[language] ?? "Male",
        appTranslation?.translations?.adminPage?.female?.[language] ?? "Female",
      ],
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 250,
            },
          },
        },
      ],
      dataLabels: {
        formatter(val, opts) {
          return [
            opts.w.globals.initialSeries[opts.seriesIndex],
            opts.w.globals.labels[opts.seriesIndex],
          ];
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -35,
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 80,
        labels: {
          colors: "#fff",
        },
      },
      stroke: {
        show: false,
      },
    },
  };

  return temp[0] == 0 && temp[1] == 0 ? (
    <div className="w-full h-full flex items-center justify-center">
      <p className="text-white font-light text-sm">{appTranslation?.translations?.adminPage?.noData?.[language] ?? "No data"}</p>
    </div>
  ) : (
    <Chart
      options={state.options}
      series={state.series}
      type="pie"
      width={380}
    />
  );
}
