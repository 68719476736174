export default function AccordianIcon({ className , ...props}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        className={className ?? ""}
        {...props}
      >
        <g
          id="chevron-circle-left-Regular"
          transform="translate(23) rotate(90)"
        >
          <g id="chevron-circle-left-Light">
            <rect id="Path" width="23" height="23" fill="rgba(255,255,255,0)" />
            <path
              id="chevron-circle-left-Light_1_"
              d="M887.792,574l-2.51,2.51,2.509,2.509a.474.474,0,0,1-.671.67l-2.845-2.845a.474.474,0,0,1,0-.67l2.845-2.845a.475.475,0,0,1,.672.671Zm7.724,2.51a9.008,9.008,0,1,1-9.008-9.008A9.019,9.019,0,0,1,895.517,576.508Zm-.948,0a8.06,8.06,0,1,0-8.06,8.06A8.069,8.069,0,0,0,894.568,576.508Z"
              transform="translate(-875.129 -565.129)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
