import { useEffect } from "react";
import AxiosInstance from "./axios";
import { useQuery } from "@tanstack/react-query";

export async function userLogin(data) {
  return AxiosInstance.post("auth/login", data)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function userLogout(data) {
  return AxiosInstance.post("auth/logout", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function getUser() {
  return AxiosInstance.get("auth/getUser/" + localStorage.getItem("sessionID"))
    .then((res) => {
      if (!localStorage.getItem("teamer")) {
        localStorage.setItem("teamer", JSON.stringify(res.data.user.userStat));
      }
      localStorage.setItem("firstResponse", JSON.stringify(res.data));
      return res.data;
    })
    .catch((err) => new Error(err));
}

export async function getUserInfo() {
  return AxiosInstance.get(
    "auth/getUserInfo/" + localStorage.getItem("sessionID")
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => new Error(err));
}

export async function forgetPassword(data) {
  return AxiosInstance.post("auth/forget-password", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function resetPassword(data) {
  return AxiosInstance.post("auth/reset-password", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function getAllUsers() {
  return AxiosInstance.get("auth")
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function addUser(data) {
  return AxiosInstance.post("auth/addUser", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function updateUser(data) {
  return AxiosInstance.post("auth/updateUser", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function deleteUser(id) {
  return AxiosInstance.delete(`auth/${id}`)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useUsers() {
  return useQuery(["usersData"], getAllUsers);
}

export function useUserInfo() {
  return useQuery(["userInfo"], getUserInfo);
}

export function useUserDetails() {
  const { data, isLoading, error } = useQuery(["userDetails"], getUser);
  const firstResponse = data
    ? data
    : JSON.parse(localStorage.getItem("firstResponse"));

  return {
    data: firstResponse,
    isLoading: firstResponse ? false : isLoading,
    error,
  };
}

export async function groupJoinRequest(data) {
  return AxiosInstance.post("auth/group-join-request", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function switchDevice(data) {
  return AxiosInstance.post("auth/switchDevice", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export async function updateUserStat(data) {
  return AxiosInstance.post("auth/updateUserStat", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}
