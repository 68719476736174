import BarGraph from "./BarGraph";
import { useEffect, useState } from "react";
import SelectField from "./SelectField";
import { useUserDetails } from "../api/userAuth";
import { useAppTranslations } from "../api/translations";
import { useUserLanguage } from "../context/userLanguage";

export default function ExercisesByMuscles({ setTotalExercises }) {
  const { language } = useUserLanguage();
  const { data: appTranslation } = useAppTranslations();
  const { data: userData, isLoading, isError } = useUserDetails();

  const [exercisesByMuscle, setExercisesByMuscle] = useState({
    male: true,
    female: false,
    muscle: [],
  });
  const [exercisesCount, setExercisesCount] = useState({
    male: {},
    female: {},
    both: {},
  });
  const [data, setData] = useState({});
  useEffect(() => {
    if (isLoading) return;
    if (isError) return;
    if (!userData) return;
    let temp;
    for (const key in userData?.user.group?.male ?? {}) {
      temp = {
        ...temp,
        male: {
          ...(temp?.male ?? {}),
          [key]: Object.keys(userData?.user.group?.male[key])
            .map(
              (item) =>
                Object.keys(userData?.user.group?.male[key][item]).length
            )
            .reduce(function (accumulator, currentValue) {
              return accumulator + currentValue;
            }, 0),
        },
      };
    }
    for (const key in userData?.user.group?.female ?? {}) {
      temp = {
        ...temp,
        female: {
          ...(temp?.female ?? {}),
          [key]: Object.keys(userData?.user.group?.female[key] ?? {})
            .map(
              (item) =>
                Object.keys(userData?.user.group?.female[key][item]).length
            )
            .reduce(function (accumulator, currentValue) {
              return accumulator + currentValue;
            }, 0),
        },
      };
    }
    let { male } = temp ?? {};
    let both = {};
    for (const key in temp?.female ?? {}) {
      both = {
        ...both,
        [key]: temp.female[key] + male[key],
      };
    }
    temp = {
      ...temp,
      both,
    };
    let totaExCount = 0;
    for (const key in temp.both) {
      totaExCount += temp.both[key];
    }
    setTotalExercises(totaExCount);
    setExercisesCount({ ...temp });
  }, [isLoading, isError, userData]);

  useEffect(() => {
    let temp = {};
    let temp2 = {};
    Object.keys(exercisesCount["male"] ?? {}).length > 0 &&
      Object.keys(exercisesCount["male"] ?? {}).map((muscle) => {
        if (
          exercisesByMuscle.muscle.length === 0 ||
          exercisesByMuscle.muscle.includes(muscle)
        ) {
          temp = {
            ...temp,
            [muscle]: exercisesCount["male"][muscle],
          };
        }
      });
    Object.keys(exercisesCount["female"] ?? {}).length > 0 &&
      Object.keys(exercisesCount["female"] ?? {}).map((muscle) => {
        if (
          exercisesByMuscle.muscle.length === 0 ||
          exercisesByMuscle.muscle.includes(muscle)
        ) {
          temp2 = {
            ...temp2,
            [muscle]: exercisesCount["female"][muscle],
          };
        }
      });
    setData((pre) => ({ ...pre, male: temp, female: temp2 }));
  }, [exercisesCount, exercisesByMuscle]);
  return (
    <>
      <div className="w-full px-6 pb-2">
        <p className="text-white text-lg font-medium">
        {appTranslation?.translations?.adminPage?.numOfExerciseByMuscle?.[language] ??
            "Num. Exercises by Muscle"}
        </p>
        <div className="flex items-center justify-start gap-4 mt-2">
          <SelectField
            value={exercisesByMuscle.muscle}
            setValue={(value) => {
              if (exercisesByMuscle.muscle.includes(value)) {
                setExercisesByMuscle((pre) => ({
                  ...pre,
                  muscle: [...pre.muscle.filter((muscle) => muscle !== value)],
                }));
              } else {
                setExercisesByMuscle((pre) => ({
                  ...pre,
                  muscle: [...pre.muscle, value],
                }));
              }
            }}
            className="w-48"
          >
            {Object.keys(userData?.user.group?.female ?? {})?.map((muscle) => {
              return <option value={muscle}dir={language == 'en' ? 'ltr' : 'rtl'}>{appTranslation?.translations?.muscles?.[muscle]?.[language] ?? muscle}</option>;
            }) ?? []}
          </SelectField>
        </div>
      </div>
      <div className="w-full px-6 pb-6">
        <BarGraph male={data.male} female={data.female} />
      </div>
    </>
  );
}
