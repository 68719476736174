import * as THREE from "three";
import model from "../assets/male.fbx";
import { memo, useEffect, forwardRef } from "react";
import { useFBX } from "@react-three/drei";
import { useUserGender } from "../context/userGender";

export const malePartModel = {
  A: "Chest",
  E: "Biceps",
  B: "Abdominals",
  D: "Calves",
  C: "Quads",
  All001: "Shoulders",
  All005: "Glutes",
  All003: "Traps (mid-back)",
  All015: "Lats",
  All004: "Triceps",
  All006: "Hamstrings",
  All008: "Forearms",
  All011: "Obliques",
  All017: "Lower back",
  All010: "Traps",
};
export const malePart = {
  Chest: {
    value: "A",
    angle: 0,
  },
  Biceps: {
    value: "E",
    angle: 0,
  },
  Abdominals: {
    value: "B",
    angle: 0,
  },
  Calves: {
    value: "D",
    angle: 0.1,
  },
  Quads: {
    value: "C",
    angle: 0,
  },
  Shoulders: {
    value: "All001",
    angle: 0,
  },
  Glutes: {
    value: "All005",
    angle: 0.1,
  },
  Traps: {
    value: "All010",
    angle: 0.2,
  },
  Lats: {
    value: "All015",
    angle: 0.1,
  },
  Triceps: {
    value: "All004",
    angle: 0.1,
  },
  Hamstrings: {
    value: "All006",
    angle: 0.1,
  },
  Forearms: {
    value: "All008",
    angle: 0.1,
  },
  Obliques: {
    value: "All011",
    angle: 0,
  },
  "Traps (mid-back)": {
    value: "All003",
    angle: 0.1,
  },
  "Lower back": {
    value: "All017",
    angle: 0.1,
  },
};
const MaleModel = forwardRef(
  ({ setSelectedPart, bodypart, multiple, ...props }, ref) => {
    const { gender, setGender } = useUserGender();
    let obj = useFBX(model);
    function handleClick(event) {
      event.stopPropagation();
      if (!setGender || !setSelectedPart) return;
      setSelectedPart(malePartModel[event.object.name]);
      setGender("male");
      for (const key in malePartModel) {
        if (key == event.object.name) {
          obj.getObjectByName(key).material.color.setHex(0x00ff00);
        } else {
          if (!multiple)
            obj.getObjectByName(key).material.color.setHex(0xe7e7e7);
        }
      }
      return;
    }
    if (bodypart) {
      try {
        obj
          .getObjectByName(malePart[bodypart]?.value)
          .material.color.setHex(0x00ff00);
      } catch (err) {}
      try {
        if (malePart[bodypart]?.angle !== 0) {
          obj.rotateOnAxis(
            new THREE.Vector3(0, 1, 0),
            malePart[bodypart]?.angle
          );
        }
      } catch (e) {}
    }

    useEffect(() => {
      if (gender && gender !== "male") {
        RemoveColorMale();
      }
      if (ref) ref.current = RemoveColorMale;
    }, [gender]);

    useEffect(() => {
      return () => {
        RemoveColorMale();
      };
    }, []);

    function RemoveColorMale(muscle) {
      for (const key in malePartModel) {
        if (muscle) {
          if (muscle == malePartModel[key]) {
            obj.getObjectByName(key).material.color.setHex(0xe7e7e7);
          }
        } else {
          try {
            obj.getObjectByName(key).material.color.setHex(0xe7e7e7);
          } catch (e) {}
        }
      }
    }

    return (
      <primitive object={obj} onClick={(e) => handleClick(e)} dispose={null} />
    );
  }
);

export default memo(MaleModel);
