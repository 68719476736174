import AxiosInstance from "./axios";
import { useQuery } from "@tanstack/react-query";

export function getAllGroups() {
  return AxiosInstance.get("groups")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addGroup(data) {
  return AxiosInstance.post("groups/add", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addMemberToGroup({ groupId, memberId }) {
  return AxiosInstance.post(`groups/addMember/${groupId}`, { memberId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function removeMemberFromGroup({ groupId, memberId }) {
  return AxiosInstance.post(`groups/removeMember/${groupId}`, { memberId })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteGroup(groupId) {
  return AxiosInstance.delete(`groups/delete/${groupId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function enableDisableExercise(data) {
  return AxiosInstance.put(`groups/enableDisableExercise`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addExercise({ groupId, ...data }) {
  let formData = new FormData();
  formData.append("frontView", data.gifTitles[0]);
  formData.append("sideView", data.gifTitles[1]);
  for (const key in data) {
    if (key !== "gifTitles") {
      formData.append(key, JSON.stringify(data[key]));
    }
  }
  let config = {
    method: "post",
    data: formData,
  };
  return AxiosInstance(`groups/addExercise/${groupId}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function editExercise({ groupId, exerciseId, ...data }) {
  return AxiosInstance(`groups/editExercise/${groupId}/${exerciseId}`, {
    method: "put",
    data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function useGroups() {
  return useQuery(["gropsData"], getAllGroups);
}
