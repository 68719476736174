import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useUserLanguage } from "../context/userLanguage";
import DateTimeBlock from "./DateTimeBlock";
import { useUserAuth } from "../context/authProvider";
import Loader from "./Loader";
import { useMutation } from "@tanstack/react-query";
import { userLogin, updateUserStat, useUserDetails } from "../api/userAuth";
import { useAppTranslations } from "../api/translations";
import { useAppData } from "../context/appData";
import { useSocket } from "../context/socket";
import Modal from "./Modal";

export default function AppLayout({ children }) {
  const {
    data: appTranslation,
    isLoading: appTranslationLoading,
    isError: appTranslationError,
  } = useAppTranslations();
  const { data: userStat } = useAppData();
  const { setUser, setAdmin, admin } = useUserAuth();
  const { pathname } = useLocation();
  const { data: userData, isLoading, isError } = useUserDetails();
  const { socket } = useSocket();
  const [logoutWarning, setLogoutWarning] = useState(false);
  const updateStatMutaion = useMutation((data) => updateUserStat(data), {
    onSuccess: (data) => {
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      setAdmin(null);
      setLogoutWarning(false);
    },
    onError: (error) => {
      sessionStorage.clear();
      localStorage.clear();
      setUser(null);
      setAdmin(null);
      setLogoutWarning(false);
    },
  });

  useEffect(() => {
    if(userData?.user?.sessionID)
    {
      socket.emit('join', userData?.user?.sessionID)
    }
    socket.on("switchDevice", ({ userId }) => {
      console.log("userLoggedOut", userId, userData?.user?._id);
      setLogoutWarning(userId == userData?.user?._id);
    }
    );
    return () => {
      socket.off('switchDevice');
    };
  }, [userData?.user?.sessionID]);

  const loginMutaion = useMutation((data) => userLogin(data), {
    onSuccess: (data) => {
      setInputValues({
        ...inputValues,
        showPopup: false,
        error: "",
        loading: false,
      });
      sessionStorage.setItem("admin", data.user._id);
      setAdmin(data.user._id);
      navigate("/admin");
    },
    onError: (error) => {
      setInputValues({
        ...inputValues,
        error: "Wrong password, try again",
        loading: false,
      });
    },
  });

  const { language, setLanguage } = useUserLanguage();
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({
    showPopup: false,
    password: "",
    error: "",
    loading: false,
  });
  useEffect(() => {
    if (isLoading) return;
    if (userData) return;
    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
    setAdmin(null);
  }, [isLoading, userData]);
  function handleLogin(e) {
    e.preventDefault();
    loginMutaion.mutate({
      email: userData.user.email,
      password: inputValues.password,
    });
  }
  return (
    <>
      {logoutWarning ? (
        <Modal
          title="You have logged in from another device"
          confirmText="Ok"
          onConfirm={() => {
            updateStatMutaion.mutate({ userStat });
          }}
          cancelText=""
        />
      ) : (
        ""
      )}
      {loginMutaion.isLoading ||
      appTranslationLoading ||
      updateStatMutaion.isLoading ? (
        <Loader />
      ) : (
        ""
      )}
      {inputValues.showPopup && (
        <div className="z-[60] fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white w-[30%] h-[30%] flex flex-col justify-center items-center">
            <h1 className="text-2xl font-bold">Admin</h1>
            {inputValues.error && (
              <p className="text-red-500 text-sm">{inputValues.error}</p>
            )}
            <input
              type="password"
              className="border-2 border-[#006FA1] rounded-md w-[80%] h-10 mt-2 px-2"
              placeholder="Password"
              value={inputValues.password}
              onChange={(e) => {
                setInputValues({ ...inputValues, password: e.target.value });
              }}
            />
            <button
              className="bg-[#006FA1] text-white w-[80%] h-10 mt-4 rounded-md"
              onClick={handleLogin}
            >
              Submit
            </button>
            <button
              className="border border-[#006FA1] text-[#006FA1] w-[80%] h-10 mt-2 rounded-md"
              onClick={() => {
                setInputValues({ ...inputValues, showPopup: false, error: "" });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="relative">
        {pathname === "/dashboard" && (
          <video
            autoPlay
            loop
            muted
            className="absolute z-[-1] w-full h-full object-fill"
          >
            <source src={require("../assets/home-bg.mp4")} fetchpriority="low" type="video/mp4" />
          </video>
        )}
        <div
          className={`bg-cover bg-no-repeat w-screen h-screen overflow-y-auto z-10
        ${
          pathname === "/dashboard"
            ? "bg-transparent"
            : "bg-[url('../public/background.webp')]"
        }
        `}
        >
          <div className="flex w-[95%] mx-auto justify-between items-center m-4">
            <DateTimeBlock />
            <div>
              <img
                src={require("../assets/LOGO.webp")}
                fetchpriority="eager"
                alt="No image"
                srcSet=""
                width={200}
                height={41}
              />
            </div>

            <ul className="text-white whitespace-nowrap flex px-4 capitalize">
              {userStat?.user?.type === "admin" ? (
                ""
              ) : (
                <li
                  className="mr-12 cursor-pointer"
                  onClick={() => {
                    setInputValues({ ...inputValues, showPopup: true });
                  }}
                >
                  {appTranslation?.translations?.general?.admin_btn?.[
                    language
                  ] ?? "Admin"}
                </li>
              )}

              <li className="mr-12 cursor-pointer">
                <a
                  target="_blank"
                  href={
                    appTranslation?.translations?.general?.terms?.[language]
                  }
                  rel="noreferrer"
                >
                  {appTranslation?.translations?.general?.terms_btn?.[
                    language
                  ] ?? "Terms of Use"}
                </a>
              </li>
              <li className="cursor-pointer">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    appTranslation?.translations?.general?.disclaimer?.[
                      language
                    ]
                  }
                >
                  {appTranslation?.translations?.general?.disclaimer_btn?.[
                    language
                  ] ?? "Disclaimer"}
                </a>
              </li>
            </ul>
            <div className="text-white flex gap-x-3">
              <span
                className={`py-2 cursor-pointer
              ${language === "en" ? "text-white" : "text-[#707070]"}
            `}
                onClick={() => {
                  setLanguage("en");
                  localStorage.setItem("language", "en");
                }}
              >
                ENG
              </span>
              <span
                className={`border-l-2 pl-2 py-2 border-[#006FA1] cursor-pointer
              ${language === "he" ? "text-white" : "text-[#707070]"}
            `}
                onClick={() => {
                  setLanguage("he");
                  localStorage.setItem("language", "he");
                }}
              >
                עבר
              </span>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}
