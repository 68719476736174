import Input from "../../components/Input";
import SelectField from "../../components/SelectField";
import Button from "../../components/Button";
import { useGroups } from "../../api/groups";
import { useState } from "react";
import { useNotification } from "../../AppNotification/NotificationProvider";
import { addUser, updateUser } from "../../api/userAuth";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../index";
import Loader from "../../components/Loader";

export default function AddNewUser({ setAddNewUser, userInfo, setUserInfo }) {
  const notification = useNotification();
  const { data: groups, isLoading, isError } = useGroups();
  const [inputValues, setInputValues] = useState({
    email: userInfo ? userInfo.email : "",
    name: userInfo ? userInfo.name : "",
    phone: userInfo ? userInfo.phone : "",
    group: userInfo ? userInfo.group?.name ?? "" : "",
    type: userInfo ? userInfo.type : "admin",
    dueDate: userInfo ? userInfo.due_date : "",
    password: userInfo ? userInfo.password : "",
  });
  const addUserMutation = useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["usersData"] });
      queryClient.invalidateQueries({ queryKey: ["gropsData"] });
      notification({
        type: "SUCCESS",
        message: "User added successfully",
      });
      setAddNewUser(false);
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });
  const updateMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["usersData"] });
      queryClient.invalidateQueries({ queryKey: ["gropsData"] });
      notification({
        type: "SUCCESS",
        message: "User updated successfully",
      });
      setUserInfo(null);
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  function handleAddNewUser() {
    if (Object.values(inputValues).every((item) => item)) {
      if (userInfo) {
        const { password, ...restInputs } = inputValues;
        updateMutation.mutate({
          ...restInputs,
          group: groups.filter((item) => item.name == inputValues.group)[0]._id,
          id: userInfo._id,
        });
        return;
      }
      addUserMutation.mutate({
        ...inputValues,
        group: groups.filter((item) => item.name == inputValues.group)[0]._id,
      });
    } else {
      notification({
        type: "ERROR",
        message: "All fields are required",
      });
    }
  }
  return (
    <>
      {(addUserMutation.isLoading || updateMutation.isLoading) && <Loader />}
      <div>
        <h1 className="text-white font-medium text-2xl">
          {userInfo ? "Edit" : "Add new"} user
        </h1>

        <div className="flex flex-wrap gap-6 max-w-4xl mt-6">
          <Input
            className="w-[250px]"
            type="email"
            placeholder="Email"
            value={inputValues.email}
            onChange={(e) =>
              setInputValues((pre) => ({ ...pre, email: e.target.value }))
            }
          />
          <Input
            className="w-[250px]"
            type="text"
            placeholder="Name"
            value={inputValues.name}
            onChange={(e) =>
              setInputValues((pre) => ({ ...pre, name: e.target.value }))
            }
          />
          <Input
            className="w-[250px]"
            type="tel"
            placeholder="Phone"
            value={inputValues.phone}
            onChange={(e) =>
              setInputValues((pre) => ({ ...pre, phone: e.target.value }))
            }
          />
          <SelectField
            selectedIcon={false}
            className="w-[250px]"
            placeholder="Type"
            value={inputValues.type}
            setValue={(val) => setInputValues((pre) => ({ ...pre, type: val }))}
          >
            <option value="admin">Admin</option>
            <option value="admin-pro">Admin pro</option>
            <option value="owner">Owner</option>
          </SelectField>
          <Input
            className="w-[250px]"
            id="due-date"
            autoComplete="off"
            type="text"
            placeholder="Due Date (select date)"
            onFocus={() => {
              document.getElementById("due-date").type = "date";
            }}
            onBlur={() => {
              document.getElementById("due-date").type = "text";
            }}
            value={inputValues.dueDate}
            onChange={(e) =>
              setInputValues((pre) => ({ ...pre, dueDate: e.target.value }))
            }
          />
          <SelectField
            selectedIcon={false}
            className="w-[250px]"
            placeholder="Group"
            value={inputValues.group}
            setValue={(val) =>
              setInputValues((pre) => ({ ...pre, group: val }))
            }
          >
            {groups?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </SelectField>
          <Input
            className="w-[250px]"
            type="password"
            placeholder="Password"
            value={inputValues.password}
            onChange={(e) =>
              setInputValues((pre) => ({ ...pre, password: e.target.value }))
            }
          />
        </div>
        <div className="flex justify-end max-w-3xl gap-6">
          <Button
            className="rounded-full border-0 bg-tranpsrent mt-4 text-primary hover:text-white"
            onClick={() => {
              setUserInfo && setUserInfo(null);
              setAddNewUser && setAddNewUser(false);
            }}
          >
            Cancel
            <hr className="bg-primary hover:bg-transparent border-0 h-[1px]" />
          </Button>
          <Button
            className="rounded-full border-0 bg-primary hover:bg-primary mt-4"
            onClick={handleAddNewUser}
          >
            {userInfo ? "Update" : "Add"}
            &nbsp;user
          </Button>
        </div>
      </div>
    </>
  );
}
