import Clock from "react-live-clock";

export default function DateTimeBlock(props) {
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}`;
  return (
    <div className="flex flex-col min-w-[140px]">
      <Clock
        className="text-[#00FFDC] text-[3rem] font-bold"
        format={"HH:mm"}
        ticking={true}
        blinking={true}
      />
      <h1 className="text-white text-2xl font-semibold">{date}</h1>
    </div>
  );
}
