export default function DeleteIcon({ className, ...props }) {
  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ""}
      {...props}
    >
      <path
        d="M25 1.77778H18.75L16.9643 0H8.03571L6.25 1.77778H0V5.33333H25M1.78571 28.4444C1.78571 29.3874 2.16199 30.2918 2.83176 30.9586C3.50153 31.6254 4.40994 32 5.35714 32H19.6429C20.5901 32 21.4985 31.6254 22.1682 30.9586C22.838 30.2918 23.2143 29.3874 23.2143 28.4444V7.11111H1.78571V28.4444Z"
        fill="#F24E1E"
      />
    </svg>
  );
}
