export default function MailIcon({ className, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.966"
      height="15.965"
      viewBox="0 0 15.966 15.965"
      className={className ?? ""}
      {...props}
    >
      <g id="Group_38" data-name="Group 38" transform="translate(0 0)">
        <path
          id="Path_340"
          data-name="Path 340"
          d="M15.964,6.108a.251.251,0,0,0-.011-.054.266.266,0,0,0-.015-.046.239.239,0,0,0-.027-.041.251.251,0,0,0-.035-.041s-.006-.009-.011-.013L13.306,3.926V1.866a.8.8,0,0,0-.8-.8H9.624L8.469.171a.789.789,0,0,0-.97,0l-1.155.9H3.46a.8.8,0,0,0-.8.8v2.06L.1,5.914S.1,5.923.092,5.926a.25.25,0,0,0-.035.041.238.238,0,0,0-.027.041.263.263,0,0,0-.015.046A.252.252,0,0,0,0,6.107c0,.006,0,.01,0,.016v9.048a.789.789,0,0,0,.156.47s0,.005,0,.007l.009.007A.794.794,0,0,0,.8,15.97h14.37a.8.8,0,0,0,.631-.315s.005,0,.007-.006,0-.005,0-.007a.79.79,0,0,0,.156-.47V6.124C15.967,6.118,15.964,6.114,15.964,6.108ZM7.824.591a.255.255,0,0,1,.317,0l.614.477H7.212ZM.866,15.438l6.958-5.4a.255.255,0,0,1,.317,0l6.96,5.4Zm14.569-.415L8.469,9.613a.79.79,0,0,0-.97,0L.532,15.023V6.529L4.893,9.915a.266.266,0,0,0,.326-.421L.789,6.055,2.661,4.6V6.656a.266.266,0,0,0,.532,0V1.866A.266.266,0,0,1,3.46,1.6h9.048a.266.266,0,0,1,.266.266v4.79a.266.266,0,1,0,.532,0V4.6l1.872,1.455L10.74,9.5a.266.266,0,1,0,.326.42l4.368-3.392v8.494Z"
          transform="translate(-0.001 -0.005)"
          fill="#00ffdc"
        />
        <path
          id="Path_341"
          data-name="Path 341"
          d="M159.961,72.495V71.43a3.193,3.193,0,1,0-3.193,3.193.266.266,0,0,0,0-.532,2.661,2.661,0,1,1,2.661-2.661v1.064a.532.532,0,1,1-1.064,0V71.43a.266.266,0,0,0-.532,0,1.064,1.064,0,1,1-1.064-1.064.266.266,0,1,0,0-.532,1.6,1.6,0,1,0,1.076,2.773,1.062,1.062,0,0,0,2.117-.112Z"
          transform="translate(-148.785 -66.109)"
          fill="#00ffdc"
        />
      </g>
    </svg>
  );
}
