export default function Input({
  disabled = false,
  disabledBG = true,
  className,
  ...props
}) {
  return (
    <div className="relative w-content">
      <input
        {...props}
        className={`bg-white border border-gray-700 rounded py-1 px-1 disabled:cursor-default ${
          className ?? ""
        }`}
      />
      {disabled && disabledBG ? (
        <div className="absolute inset-0 bg-gray-400 bg-opacity-50 rounded"></div>
      ) : (
        ""
      )}
    </div>
  );
}
