import { useState } from "react";
import AppLayout from "../components/AppLayout";
import BackIcon from "../assets/BackIcon";
import { useAppData } from "../context/appData";
import ExercisesByMuscles from "../components/ExercisesByMuscles";
import ViewsByMuscles from "../components/ViewsByMuscles";
import ViewByGender from "../components/ViewByGender";
import ExercisesByCategory from "../components/ExercisesByCategory";
import { useAppTranslations } from "../api/translations";
import { useUserLanguage } from "../context/userLanguage";
import { useMutation } from "@tanstack/react-query";
import { userLogout, useUserDetails, forgetPassword } from "../api/userAuth";
import { useUserAuth } from "../context/authProvider";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Button from "../components/Button";
import { useNotification } from "../AppNotification/NotificationProvider";
import UserManagement from "../components/UserManagement";

export default function Admin() {
  const { data: userData, isLoading, isError } = useUserDetails();
  const notification = useNotification();
  const { setUser, setAdmin } = useUserAuth();
  const navigate = useNavigate();
  const { data: userStat } = useAppData();
  const { language } = useUserLanguage();
  const [managementTab, setManagementTab] = useState(false);
  const [totalExercises, setTotalExercises] = useState(0);
  const {
    data: appTranslation,
    isLoading: appTranslationLoading,
    isError: appTranslationError,
  } = useAppTranslations();
  const logoutMutaion = useMutation((data) => userLogout(data), {
    onSuccess: (data) => {
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      setAdmin(null);
      navigate("/");
    },
    onError: (error) => {
      sessionStorage.clear();
      localStorage.removeItem("token");
      setUser(null);
      setAdmin(null);
      navigate("/");
    },
  });
  const forgetPasswordMutation = useMutation(
    () => forgetPassword({ email: userData.user.email }),
    {
      onSuccess: () => {
        notification({
          type: "SUCCESS",
          message: "Password changed request sent to your email",
        });
      },
      onError: () => {
        notification({
          type: "ERROR",
          message: "Something went wrong, try again",
        });
      },
    }
  );
  return (
    <AppLayout>
      {(forgetPasswordMutation.isLoading || logoutMutaion.isLoading) && (
        <Loader />
      )}
      <div className="fixed bottom-6 left-6">
        <BackIcon
          onClick={() => {
            managementTab ? setManagementTab(false) : navigate(-1);
          }}
        />
      </div>
      {managementTab ? (
        <div className="mt-8">
          <div className="w-[calc(100%-70px)] min-w-[400px] flex flex-col justify-start mx-auto gap-3">
            <UserManagement />
          </div>
        </div>
      ) : (
        <div className="mt-2">
          <div className="w-[calc(100%-70px)] min-w-[400px] flex items-center justify-end mx-auto gap-3">
            {!isLoading && userData?.user?.type !== "admin" ? (
              <Button
                onClick={() => {
                  setManagementTab(true);
                }}
              >
                {appTranslation?.translations?.adminPage?.Management?.[
                  language
                ] ?? "management"}
              </Button>
            ) : (
              ""
            )}
            <Button
              onClick={() => {
                forgetPasswordMutation.mutate();
              }}
            >
              {appTranslation?.translations?.adminPage?.changePassword?.[
                language
              ] ?? "change password"}
            </Button>
            <Button
              disabled={logoutMutaion.isLoading}
              className="text-white text-base border-[#92FA4B] border w-fit px-5 py-[3px] rounded-[10px] capitalize"
              onClick={() => {
                logoutMutaion.mutate({ userStat });
              }}
            >
              {appTranslation?.translations?.adminPage?.logout?.[language] ??
                "Logout"}
            </Button>
          </div>
          <div className="flex items-start w-full h-full">
            <div className="w-full">
              <div className="flex flex-col w-full h-full items-center justify-center">
                <div className="flex w-full flex-wrap gap-3 justify-center mt-3">
                  <div className="min-w-[400px] h-auto bg-[#0D3475] p-2 flex flex-col items-center justify-center w-[calc(100%-70px)]  xl:w-[calc(50%-50px)]">
                    <ViewByGender />
                  </div>
                  <div className="min-w-[400px] h-auto bg-[#0D3475] p-2 flex flex-col items-center justify-center w-[calc(100%-70px)] xl:w-[calc(50%-50px)]">
                    <div className="flex flex-col w-full h-full items-center justify-center">
                      <div className="flex w-full flex-wrap gap-4 justify-start mt-2">
                        <div className="min-w-[100px] h-[130px] xl:h-[150px] border border-white p-4 flex flex-col items-center justify-center w-[calc(50%-10px)] gap-7">
                          <h2 className="font-nomal text-4xl text-white text-center">
                            {totalExercises ?? 0}
                          </h2>
                          <p className="text-white text-center font-light text-lg">
                            {appTranslation?.translations?.adminPage
                              ?.numOfExercises?.[language] ??
                              "Number of exercises by muscle"}
                          </p>
                        </div>
                        <div className="min-w-[100px] h-[130px] xl:h-[150px] border border-white p-2 flex flex-col items-center justify-center w-[calc(50%-10px)] gap-7">
                          <h2 className="font-nomal text-4xl text-white text-center">
                            {(userStat?.genderClick?.male?.clicks?.length ??
                              0) +
                              (userStat?.genderClick?.female?.clicks?.length ??
                                0)}
                          </h2>
                          <p className="text-white text-center font-light text-lg">
                            {appTranslation?.translations?.adminPage
                              ?.numOfViewThisMonth?.[language] ??
                              "Number of views this month"}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full flex-wrap gap-4 justify-start mt-2 xl:mt-4">
                        <div className="min-w-[100px] h-[130px] xl:h-[150px] border border-white p-4 flex flex-col items-center justify-center w-[calc(50%-10px)] gap-7">
                          <h2 className="font-nomal text-4xl text-white text-center">
                            {userStat?.genderClick?.male?.clicks?.length ?? 0}
                          </h2>
                          <p className="text-white text-center font-light text-lg">
                            {appTranslation?.translations?.adminPage
                              ?.maleClicks[language] ?? "Male clicks"}
                          </p>
                        </div>
                        <div className="min-w-[100px] h-[130px] xl:h-[150px] border border-white p-4 flex flex-col items-center justify-center w-[calc(50%-10px)] gap-7">
                          <h2 className="font-nomal text-4xl text-white text-center">
                            {userStat?.genderClick?.female?.clicks?.length ?? 0}
                          </h2>
                          <p className="text-white text-center font-light text-lg">
                            {appTranslation?.translations?.adminPage
                              ?.femaleClicks?.[language] ?? "Female clicks"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-wrap gap-3 justify-center mt-3">
                  <div className="min-w-[400px] h-fit bg-[#0D3475] p-2 flex flex-col items-center justify-center w-[calc(100%-70px)] xl:w-[calc(100%-85px)]">
                    <ViewsByMuscles />
                  </div>
                  <div className="min-w-[400px] h-fit bg-[#0D3475] p-2 flex flex-col items-center justify-center w-[calc(100%-70px)] xl:w-[calc(100%-85px)]">
                    <ExercisesByCategory />
                  </div>
                  <div className="min-w-[400px] h-fit bg-[#0D3475] p-2 flex flex-col items-center justify-center w-[calc(100%-70px)] xl:w-[calc(100%-85px)]">
                    <ExercisesByMuscles setTotalExercises={setTotalExercises} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
}
