import React, { useState } from "react";
import SelectField from "./SelectField";
import Image from "./Image";
import AddIcon from "../assets/AddIcon";
import DeleteIcon from "../assets/DeleteIcon";
import Button from "./Button";
import { useUserLanguage } from "../context/userLanguage";
import { useNotification } from "../AppNotification/NotificationProvider";
import { omit } from "./util";
import { useMutation } from "@tanstack/react-query";
import {
  enableDisableExercise,
  addExercise,
  editExercise,
} from "../api/groups";
import { queryClient } from "../index";
import { useUserDetails } from "../api/userAuth";
import Input from "./Input";
import Loader from "./Loader";
import { useAppTranslations } from "../api/translations";

const getVideoDuration = (file, setValue, callback) => {
  let videoDuration = 0;

  const videoElement = document.createElement("video");
  videoElement.preload = "metadata";

  videoElement.onloadedmetadata = function () {
    window.URL.revokeObjectURL(videoElement.src);
    const { duration } = videoElement;
    if (duration > 30) {
      callback();
      setValue("");
    }
    videoDuration = duration;
  };

  videoElement.src = URL.createObjectURL(file);

  return videoDuration;
};

function VideoComponent({ value, setValue, title, custom, subtitle }) {
  const notification = useNotification();
  return (
    <>
      {value ? (
        <Image
          src={
            typeof value == "string"
              ? custom
                ? process.env.REACT_APP_API_URL + value
                : process.env.REACT_APP_VIDEOS_URL + value + ".mp4"
              : URL.createObjectURL(value)
          }
        />
      ) : (
        <label className="flex flex-col py-4 px-4 rounded items-center bg-gray-300">
          <h2 className="">{title ?? "Side View"}</h2>
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-4"
          >
            <path
              d="M12.375 12.0862V20.9137C12.375 21.5462 12.6637 22.0275 13.2412 22.3575C13.8187 22.6875 14.3825 22.66 14.9325 22.275L21.78 17.9025C22.3025 17.5725 22.5637 17.105 22.5637 16.5C22.5637 15.895 22.3025 15.4275 21.78 15.0975L14.9325 10.725C14.3825 10.34 13.8187 10.3125 13.2412 10.6425C12.6637 10.9725 12.375 11.4537 12.375 12.0862ZM16.5 33C14.2175 33 12.0725 32.5666 10.065 31.6998C8.0575 30.833 6.31125 29.6576 4.82625 28.1737C3.34125 26.6887 2.1659 24.9425 1.3002 22.935C0.4345 20.9275 0.0011 18.7825 0 16.5C0 14.2175 0.4334 12.0725 1.3002 10.065C2.167 8.0575 3.34235 6.31125 4.82625 4.82625C6.31125 3.34125 8.0575 2.1659 10.065 1.3002C12.0725 0.4345 14.2175 0.0011 16.5 0C18.7825 0 20.9275 0.4334 22.935 1.3002C24.9425 2.167 26.6887 3.34235 28.1737 4.82625C29.6587 6.31125 30.8346 8.0575 31.7014 10.065C32.5682 12.0725 33.0011 14.2175 33 16.5C33 18.7825 32.5666 20.9275 31.6998 22.935C30.833 24.9425 29.6576 26.6887 28.1737 28.1737C26.6887 29.6587 24.9425 30.8346 22.935 31.7014C20.9275 32.5682 18.7825 33.0011 16.5 33Z"
              fill="#111111"
            />
          </svg>
          <p className="text-xs mt-4">{subtitle ?? "Drag and drop or chose a file"}</p>
          <Input
            id={`video-${title.split(" ").join("-")}`}
            type="file"
            name="videoInput"
            accept="video/*"
            className="hidden mt-4"
            onChange={(e) => {
              if (e.target.files[0].type.split("/")[0] === "video") {
                setValue(e.target.files[0]);
                getVideoDuration(e.target.files[0], setValue, () => {
                  notification({
                    type: "ERROR",
                    message: "Video duration should be less than 30 seconds",
                  });
                });
              } else {
                notification({
                  type: "ERROR",
                  message: "Please upload a valid video file",
                });
              }
            }}
            hidden
          />
        </label>
      )}
    </>
  );
}

export default function ExerciseAddEdit({
  exercises,
  gender,
  muslce,
  equipment,
  setAppstate,
}) {
  const { data: userData, isLoading, isError } = useUserDetails();
  const { data: appTranslation } = useAppTranslations();
  const { language } = useUserLanguage();
  const notification = useNotification();
  const [inputValue, setInputValue] = useState({
    title: {
      en: "",
      he: "",
    },
    instructions: {
      0: {
        en: "",
        he: "",
      },
    },
    videos: {
      frontView: "",
      sideView: "",
    },
    dificulty: "Beginner",
    sets: 0,
    reps: 0,
  });
  const [selectedExercise, setSelectedExercise] = useState("");
  const [exerciseFromList, setExerciseFromList] = useState({});

  const enableDisableMutation = useMutation(enableDisableExercise, {
    onSuccess: () => {
      queryClient.invalidateQueries(["userDetails"]);
      notification({
        type: "SUCCESS",
        message: "Exercise updated successfully",
      });
      setExerciseFromList({});
      setAppstate({
        name: "",
        exercises: [],
      });
    },
    onError: () => {
      notification({
        type: "ERROR",
        message: "Something went wrong",
      });
    },
  });

  const addExerciseMutation = useMutation(addExercise, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["userDetails"]);
      notification({
        type: "SUCCESS",
        message: "Exercise added successfully",
      });
      setExerciseFromList({});
      setAppstate({
        name: "",
        exercises: [],
      });
    },
    onError: () => {
      notification({
        type: "ERROR",
        message: "Something went wrong",
      });
    },
  });
  const editExerciseMutation = useMutation(editExercise, {
    onSuccess: () => {
      queryClient.invalidateQueries(["userDetails"]);
      notification({
        type: "SUCCESS",
        message: "Exercise updated successfully",
      });
      setExerciseFromList({});
      setAppstate({
        name: "",
        exercises: [],
      });
    },
    onError: () => {
      notification({
        type: "ERROR",
        message: "Something went wrong",
      });
    },
  });

  function handleUpload(event, defaultExercise) {
    event.preventDefault();
    if (!inputValue.title.en || !inputValue.title.he) {
      notification({
        type: "ERROR",
        message: `Title is mendatory`,
      });
      return;
    }
    for (const key in inputValue.instructions) {
      if (
        !inputValue.instructions[key].en ||
        !inputValue.instructions[key].he
      ) {
        notification({
          type: "ERROR",
          message: `Instruction ${Number(key) + 1} ${
            !inputValue.instructions[key].he ? "hebrew" : "english"
          } translation is missing`,
        });
        return;
      }
    }
    if (!inputValue.videos.frontView || !inputValue.videos.sideView) {
      notification({
        type: "ERROR",
        message: `${
          inputValue.videos.frontView ? "side view" : "front view"
        } video is mendatory`,
      });
      return;
    }

    if (exerciseFromList.id || exerciseFromList._id) {
      editExerciseMutation.mutate({
        id: exerciseFromList.id ? exerciseFromList.id : exerciseFromList._id,
        exerciseId: exerciseFromList.id,
        groupId: userData?.user?.group?._id,
        gender: {
          en: gender,
          he: gender,
        },
        muscleGroup: {
          en: muslce,
          he: muslce,
        },
        title: {
          en: inputValue.title.en,
          he: inputValue.title.he,
        },
        active: true,
        custom: exerciseFromList.custom ? true : false,
        equipment: {
          en: equipment,
          he: equipment,
        },
        difficulty: {
          en: inputValue.dificulty,
          he: inputValue.dificulty,
        },
        instructions: {
          en: Object.keys(inputValue.instructions).map((item) => {
            return inputValue.instructions[item].en;
          }),
          he: Object.keys(inputValue.instructions).map((item) => {
            return inputValue.instructions[item].he;
          }),
        },
        gifTitles: [inputValue.videos.frontView, inputValue.videos.sideView],
        defaultExercise: defaultExercise,
      });
    } else {
      addExerciseMutation.mutate({
        defaultExercise: defaultExercise,
        groupId: userData?.user?.group?._id,
        gender: {
          en: gender,
          he: gender,
        },
        muscleGroup: {
          en: muslce,
          he: muslce,
        },
        title: {
          en: inputValue.title.en,
          he: inputValue.title.he,
        },
        active: true,
        custom: true,
        equipment: {
          en: equipment,
          he: equipment,
        },
        difficulty: {
          en: inputValue.dificulty,
          he: inputValue.dificulty,
        },
        instructions: {
          en: Object.keys(inputValue.instructions).map((item) => {
            return inputValue.instructions[item].en;
          }),
          he: Object.keys(inputValue.instructions).map((item) => {
            return inputValue.instructions[item].he;
          }),
        },
        gifTitles: [inputValue.videos.frontView, inputValue.videos.sideView],
      });
    }
  }

  return (
    <>
      {(enableDisableMutation.isLoading || addExerciseMutation.isLoading) && (
        <Loader />
      )}
      <div className="flex flex-col gap-6 w-[fill]">
        <div className="flex w-full gap-2">
          <h2 className="text-white ">
            {appTranslation?.translations?.adminPage?.editOrRemoveExercise?.[
              language
            ] ?? "Edit or Remove exercise"}
          </h2>
          <SelectField
            value={selectedExercise}
            setValue={(value) => {
              setSelectedExercise(value);
              let selectedexercise = exercises.filter((item) => {
                if (item.title[language] === value) {
                  return item;
                }
              })[0];
              setExerciseFromList(selectedexercise);
              let instructions = {};
              selectedexercise.instructions[language].forEach((item, index) => {
                instructions[index] = {
                  en: item,
                  he: selectedexercise.instructions.he[index],
                };
              });
              setInputValue((prev) => ({
                ...prev,
                title: {
                  ...prev.title,
                  en: selectedexercise.title.en,
                  he: selectedexercise.title.he,
                },
                instructions: instructions,
                dificulty: selectedexercise.difficulty[language],
                reps: selectedexercise.reps ?? 0,
                sets: selectedexercise.sets ?? 0,
                videos: {
                  frontView: selectedexercise.gifTitles[0],
                  sideView: selectedexercise.gifTitles[1],
                },
              }));
            }}
            selectedIcon={false}
            className="max-w-[300px]"
          >
            {exercises.map((item, index) => (
              <div
                key={index.id}
                value={item.title[language]}
                disabled={!item.active}
                className="flex items-center justify-between"
                dir={language === "he" ? "rtl" : "ltr"}
              >
                {item.title[language]}
                <Image
                  src={
                    item.custom
                      ? process.env.REACT_APP_API_URL + item.gifTitles[0]
                      : `${process.env.REACT_APP_VIDEOS_URL}${item.gifTitles[0]}.mp4`
                  }
                  className="!w-28 !xl:w-28 !h-14 "
                />
              </div>
            ))}
          </SelectField>
          {exerciseFromList.id && (
            <Button
              className={
                exerciseFromList.active
                  ? "!bg-red-500 !border-red-500 !hover:bg-red-500 !rounded-full !py-0"
                  : "!bg-primary !border-primary !hover:bg-primary !rounded-full !py-0"
              }
              onClick={() => {
                enableDisableMutation.mutate({
                  active: !exerciseFromList.active,
                  id: exerciseFromList.id,
                  gender: exerciseFromList.gender.en,
                  muscleGroup: exerciseFromList.muscleGroup.en,
                  equipment: exerciseFromList.equipment.en,
                  title: exerciseFromList.title.en,
                  groupId: userData?.user.group._id,
                });
              }}
            >
              {exerciseFromList.active ? "Remove" : "Restore"}
            </Button>
          )}
        </div>
        <form onSubmit={handleUpload}>
          <div>
            <h2 className="text-white">
              {appTranslation?.translations?.adminPage?.title?.[language] ??
                "Title"}
            </h2>
            <div className="flex items-center gap-3 mt-1">
              <Input
                disabled={
                  Object.keys(exerciseFromList).length > 0
                    ? !exerciseFromList.active
                    : false
                }
                required
                type="text"
                placeholder="עב"
                value={inputValue.title.he}
                onChange={(e) =>
                  setInputValue((prev) => ({
                    ...prev,
                    title: { ...prev.title, he: e.target.value },
                  }))
                }
              />
              <Input
                disabled={
                  Object.keys(exerciseFromList).length > 0
                    ? !!exerciseFromList.active || exerciseFromList.id
                    : false
                }
                required
                type="text"
                placeholder="EN"
                value={inputValue.title.en}
                onChange={(e) =>
                  !!exerciseFromList.active ||
                  exerciseFromList.id ||
                  exerciseFromList._id
                    ? notification({
                        message: "You can't edit exercise title",
                        type: "ERROR",
                      })
                    : setInputValue((prev) => ({
                        ...prev,
                        title: { ...prev.title, en: e.target.value },
                      }))
                }
              />
            </div>
          </div>
          <div>
            <h2 className="text-white">
              {appTranslation?.translations?.adminPage?.instructions?.[
                language
              ] ?? "Instructions"}
            </h2>
            {Object.keys(inputValue.instructions).map((item, index) => {
              return (
                <div className="flex items-center gap-2 mt-2">
                  <Input
                    disabled={
                      Object.keys(exerciseFromList).length > 0
                        ? !exerciseFromList.active
                        : false
                    }
                    required
                    type="text"
                    placeholder="עב"
                    value={inputValue.instructions[item].he}
                    onChange={(e) =>
                      setInputValue((prev) => ({
                        ...prev,
                        instructions: {
                          ...prev.instructions,
                          [item]: {
                            ...prev.instructions[item],
                            he: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  <Input
                    disabled={
                      Object.keys(exerciseFromList).length > 0
                        ? !exerciseFromList.active
                        : false
                    }
                    required
                    type="text"
                    placeholder="EN"
                    value={inputValue.instructions[item].en}
                    onChange={(e) =>
                      setInputValue((prev) => ({
                        ...prev,
                        instructions: {
                          ...prev.instructions,
                          [item]: {
                            ...prev.instructions[item],
                            en: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  <AddIcon
                    className="cursor-pointer"
                    onClick={() => {
                      if (!!exerciseFromList.active) return;
                      let newInstructions = inputValue.instructions;
                      let newKey = Object.keys(newInstructions).length;
                      newInstructions[newKey] = {
                        en: "",
                        he: "",
                      };
                      setInputValue((prev) => ({
                        ...prev,
                        instructions: newInstructions,
                      }));
                    }}
                  />
                  <DeleteIcon
                    className="cursor-pointer"
                    onClick={() => {
                      if (!!exerciseFromList.active) return;
                      setInputValue((prev) => ({
                        ...prev,
                        instructions: {
                          ...omit(inputValue.instructions, item),
                        },
                      }));
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex items-center justify-center gap-3 mt-2">
            <VideoComponent
              custom={exerciseFromList.custom ?? false}
              title={
                appTranslation?.translations?.adminPage?.frontView?.[
                  language
                ] ?? "Front View"
              }
              subtitle={
                appTranslation?.translations?.adminPage?.DragAndDrop?.[language] ?? "Drag and drop or chose a file"
              }
              value={inputValue.videos.frontView}
              setValue={(val) =>
                setInputValue((prev) => {
                  return {
                    ...prev,
                    videos: { ...prev.videos, frontView: val },
                  };
                })
              }
            />
            <VideoComponent
              custom={exerciseFromList.custom ?? false}
              title={
                appTranslation?.translations?.adminPage?.sideView?.[language] ??
                "Side View"
              }
              subtitle={
                appTranslation?.translations?.adminPage?.DragAndDrop?.[language] ?? "Drag and drop or chose a file"
              }
              value={inputValue.videos.sideView}
              setValue={(val) =>
                setInputValue((prev) => {
                  return { ...prev, videos: { ...prev.videos, sideView: val } };
                })
              }
            />
          </div>
          <div className="flex items-center gap-2 mt-2">
            <SelectField
              selectedIcon={false}
              disabled={
                Object.keys(exerciseFromList).length > 0
                  ? !exerciseFromList.active
                  : false
              }
              placeholder="Difficulty"
              className="max-w-[150px]"
              value={inputValue.dificulty}
              setValue={(value) =>
                setInputValue({
                  ...inputValue,
                  dificulty: value,
                })
              }
            >
              {
                ['beginner', 'intermediate', 'advance'].map((item) => {
                  return (
                    <option value={item} dir={language == 'he' ? 'rtl' : 'ltr'}>{appTranslation?.translations?.adminPage?.[item]?.[language] ?? item}</option>
                  )
                }
                )
              }
            </SelectField>
            <h2 className="text-white">{appTranslation?.translations?.adminPage?.recommendedSets?.[language] ?? "Recommended Sets"}</h2>
            <Input
              className="w-10"
              disabled={
                Object.keys(exerciseFromList).length > 0
                  ? !exerciseFromList.active
                  : false
              }
              required
              type="number"
              placeholder="Add text"
              value={inputValue.sets}
              onChange={(e) =>
                setInputValue({
                  ...inputValue,
                  sets: e.target.value,
                })
              }
            />
            <h2 className="text-white">{appTranslation?.translations?.adminPage?.recommendedReps?.[language] ?? "Recommended Reps"}</h2>
            <Input
              className="w-10"
              disabled={
                Object.keys(exerciseFromList).length > 0
                  ? !exerciseFromList.active
                  : false
              }
              required
              type="number"
              placeholder="Add text"
              value={inputValue.reps}
              onChange={(e) =>
                setInputValue({
                  ...inputValue,
                  reps: e.target.value,
                })
              }
            />
          </div>
          <div className="flex items-center gap-2 justify-end mt-4">
            <Button
              className="!border-none text-primary rounded-full"
              onClick={() => {
                setExerciseFromList({});
                setInputValue({
                  title: {
                    en: "",
                    he: "",
                  },
                  instructions: {
                    0: {
                      en: "",
                      he: "",
                    },
                  },
                  videos: {
                    frontView: "",
                    sideView: "",
                  },
                  dificulty: "easy",
                  sets: 0,
                  reps: 0,
                });
              }}
            >
              {appTranslation?.translations?.adminPage?.clearAll?.[language] ?? "Clear all"}
            </Button>
            <Button className="bg-primary rounded-full" onClick={handleUpload}>
              {appTranslation?.translations?.adminPage?.upload?.[language] ?? "Upload"}
            </Button>
            {userData?.user.type === "owner" && (
              <Button
                className="bg-primary rounded-full"
                onClick={(e) => handleUpload(e, true)}
              >
                {exerciseFromList.id ? appTranslation?.translations?.adminPage?.updateDefault?.[language] ?? "Update Default" : appTranslation?.translations?.adminPage?.uploadDefault?.[language] ?? "Upload Default"}
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
