import { useEffect, useState, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Stage, PresentationControls } from "@react-three/drei";
import MaleModel, { malePartModel } from "./MaleModel";
import FemaleModel, { femalePartModel } from "./FemaleModel";
import { useUserLanguage } from "../context/userLanguage";
import ExerciseAddEdit from "./ExerciseAddEdit";
import UserModule from "./UserModule";
import GroupsModule from "../section/owner/groups/GroupsModule";
import { useUserDetails } from "../api/userAuth";
import Button from "./Button";
import { useAppTranslations } from "../api/translations";

export default function UserManagement() {
  const { data: userData, isLoading, isError } = useUserDetails();
  const { language } = useUserLanguage();
  const { data: appTranslation } = useAppTranslations();
  const [selectedGender, setSelectedGender] = useState("male");
  const [ownerModule, setOwnerModule] = useState("exercises");
  const [selectedPart, setSelectedPart] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const childRef = useRef(null);

  useEffect(() => {
    if (!userData) return;
    let temmp = {};
    Object.values(
      selectedGender == "male" ? malePartModel : femalePartModel
    ).map((item) => {
      Object.keys(userData?.user?.group?.[selectedGender]?.[item]).map(
        (item2) => {
          if (!temmp[item2])
            temmp[item2] =
              userData?.user?.group?.[selectedGender]?.[item][item2];
          else
            temmp[item2] = {
              ...temmp[item2],
              ...userData?.user?.group?.[selectedGender]?.[item][item2],
            };
        }
      );
    });
    setEquipment(temmp);
  }, [userData, selectedGender]);

  const [Appstate, setAppstate] = useState({
    name: "",
    exercises: [],
  });
  function toggleactions(item, name) {
    setAppstate((prev) => ({
      ...prev,
      name: name,
      exercises: Object.values(item),
    }));
  }

  return (
    <>
      {userData?.user?.type === "owner" && (
        <div className="flex gap-4 items-start">
          {["exercises", "users", "groups"].map((item, index) => (
            <p
              className="text-primary cursor-pointer px-0 py-2 capitalize"
              style={{
                borderBottom:
                  ownerModule === item
                    ? "2px solid #CFE741"
                    : "2px solid transparent",
              }}
              onClick={() => {
                setOwnerModule(item);
                setSelectedPart([]);
              }}
            >
              {appTranslation?.translations?.adminPage?.[item]?.[language] ??
                item}
            </p>
          ))}
        </div>
      )}
      {ownerModule === "exercises" ? (
        <div className="flex gap-2 w-full">
          <div className="flex flex-col gap-2 mt-20">
            {["male", "female"].map((item, index) => (
              <p
                className="text-primary cursor-pointer px-4 ml-1 py-2 capitalize"
                style={{
                  borderLeft:
                    selectedGender === item
                      ? "2px solid #CFE741"
                      : "2px solid transparent",
                }}
                onClick={() => {
                  setAppstate({
                    name: "",
                    exercises: [],
                  });
                  setSelectedGender(item);
                  setSelectedPart([]);
                }}
              >
                {appTranslation?.translations?.adminPage?.[item]?.[language] ??
                  item}
              </p>
            ))}
          </div>
          <div className=" relative">
            <p className="text-white text-center">
              {appTranslation?.translations?.adminPage
                ?.chooseOneOrMoreMuscles?.[language] ??
                "Choose 1 or more muscles"}
            </p>
            <Canvas
              dpr={[1, 2]}
              shadows
              camera={{ fov: 65 }}
              style={{ height: "50vh", width: "27vw" }}
            >
              <PresentationControls
                global
                rotation={[0, 0, 0]}
                polar={[0, 0]}
                azimuth={[-Infinity, Infinity]}
              >
                <mesh>
                  <Stage environment={null}>
                    {selectedGender === "male" ? (
                      <MaleModel
                        ref={childRef}
                        setSelectedPart={(muscle) => {
                          if (muscle) {
                            setSelectedPart([
                              ...new Set([...selectedPart, muscle]),
                            ]);
                          }
                        }}
                        multiple
                      />
                    ) : (
                      <FemaleModel
                        ref={childRef}
                        setSelectedPart={(muscle) => {
                          if (muscle) {
                            setSelectedPart([
                              ...new Set([...selectedPart, muscle]),
                            ]);
                          }
                        }}
                        multiple
                      />
                    )}
                  </Stage>
                </mesh>
              </PresentationControls>
            </Canvas>
            {/* <div className="absolute bottom-[10%] left-[20%] xl:bottom-[20%] xl:left-[27%]">
          <RotateIcon fill={"white"} className="w-3/4 xl:w-full" />
        </div> */}
            {selectedPart.length > 0 && (
              <div className="flex items-center gap-4 flex-wrap max-w-sm">
                {selectedPart.map((item, index) => (
                  <div className="text-white text-base border-primary bg-[#91fa4b53] border w-fit px-3 py-[3px] rounded-md capitalize flex items-center gap-2">
                    {appTranslation?.translations?.muscles?.[item]?.[
                      language
                    ] ?? item}
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        childRef.current(item);
                        setSelectedPart(
                          selectedPart.filter((item2) => item2 !== item)
                        );
                      }}
                    >
                      x
                    </span>
                  </div>
                ))}
                <Button
                  className={`bg-[#91fa4b53]`}
                  onClick={() => {
                    childRef.current(null);
                    setSelectedPart([]);
                    setAppstate({
                      name: "",
                      exercises: [],
                    });
                  }}
                >
                  {appTranslation?.translations?.adminPage?.resetAll?.[
                    language
                  ] ?? "reset all"}
                </Button>
              </div>
            )}
          </div>
          {selectedPart.length > 0 && (
            <div>
              <p className="text-white text-center mb-8">{appTranslation?.translations?.adminPage?.chooseEquipment?.[
                    language
                  ] ?? "Choose equipment"}</p>
              <div className="flex gap-1 xl:gap-2 flex-wrap w-[290px] xl:w-[365px] cursor-pointer h-[65vh] custom-scroll">
                {Object.keys(equipment ?? {}).map((element, index) => (
                  <div
                    onClick={() => {
                      toggleactions(equipment?.[element], element);
                    }}
                    key={index}
                    className={`flex items-center justify-center flex-col px-4 xl:px-8 py-2 xl:py-3 bg-[#0D3475] text-white ${
                      Appstate.name === element
                        ? "bg-[#5F93E9]"
                        : "bg-[#0D3475]"
                    } `}
                  >
                    <img
                      className="w-[110px] h-[72px] object-cover filter-none"
                      src={`/${element}.webp`}
                      alt={element}
                      fetchpriority="eager"
                    />
                    <span className="text-right">
                      {
                        equipment?.[element]?.[
                          Object.keys(equipment?.[element])?.[0]
                        ]?.equipment[language]
                      }
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedPart.length > 0 && Appstate.name && (
            <ExerciseAddEdit
              exercises={Appstate.exercises}
              gender={selectedGender}
              muslce={selectedPart}
              equipment={Appstate.name}
              setAppstate={setAppstate}
            />
          )}
        </div>
      ) : ownerModule === "users" ? (
        <UserModule />
      ) : (
        <GroupsModule />
      )}
    </>
  );
}
