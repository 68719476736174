export default function DropDownIcon({ className }) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ""}
    >
      <path
        d="M0.931372 0C0.218648 0 -0.138286 0.861714 0.365685 1.36569L8.43431 9.43431C8.74673 9.74673 9.25327 9.74673 9.56569 9.43431L17.6343 1.36568C18.1383 0.861712 17.7814 0 17.0686 0H0.931372Z"
        fill="white"
      />
    </svg>
  );
}
