import AccordianIcon from "../assets/AccordianIcon";
import { useState, useEffect } from "react";


export default function Accordion ({ title, children, className, dir, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false)
  }, [title])

  return (
    <div className={`flex flex-col bg-[#0D3475]  ${className ?? ''}`}>
      <div className={`flex w-full items-center justify-between px-4 py-3 ${
        dir === 'rtl' ? 'flex-row-reverse' : 'flex-row'
      }` }
      onClick={() => setIsOpen(!isOpen)}
      >
        <h1 className="text-white text-lg font-regular">{title}</h1>
        <AccordianIcon 
        className={`${!isOpen ? 'transform rotate-180' : 'transform rotate-0'} transition-all duration-500`}
         />
      </div>
      <div className={`transition-all duration-1000  px-4 
      ${
        isOpen ? 'max-h-[220px] overflow-auto mt-4 py-2' : 'max-h-0 overflow-hidden mt-1'
      }
      `}>{children}</div>
    </div>
  )
}