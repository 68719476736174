import Button from "./Button";

export default function Modal({
  isDelete = "delete",
  name,
  onConfirm,
  onCancel,
  title = null,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) {
  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center  bg-black bg-opacity-50 z-[100]">
        <div className="max-w-xl bg-white rounded-md py-6 px-3 w-full">
          {title ?? (
            <h1 className="text-center text-xl w-full">
              Are you sure you want to {isDelete}{" "}
              <span className="font-bold">{name}</span>?
            </h1>
          )}
          <div className="flex justify-center mt-20">
            {cancelText ? (
              <Button
                onClick={onCancel}
                className="bg-white hover:bg-white text-primary !py-2 !px-5 mr-8"
              >
                {cancelText}
              </Button>
            ) : (
              ""
            )}
            {confirmText ? (
              <Button
                onClick={onConfirm}
                className="bg-primary hover:bg-primary text-white !py-2 !px-5"
              >
                {confirmText}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
