import { useUsers } from "../../api/userAuth";
import Button from "../../components/Button";
import { useState } from "react";
import { useNotification } from "../../AppNotification/NotificationProvider";
import { updateUser, deleteUser } from "../../api/userAuth";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../index";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import Input from "../../components/Input";
import AddNewUser from "./AddNewUser";

export default function UsersList() {
  const notification = useNotification();
  const { data: users, isLoading, isError } = useUsers();
  const [deleteUserInfo, setDeleteUser] = useState(null);
  const [updateUserInfo, setUpdateUser] = useState(null);
  const [editUserInfo, setEditUser] = useState(null);

  const updateMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["usersData"]);
      queryClient.invalidateQueries(["gropsData"]);
      notification({
        type: "SUCCESS",
        message: "User updated successfully",
      });
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  const deleteMutation = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["usersData"]);
      queryClient.invalidateQueries(["gropsData"]);
      notification({
        type: "SUCCESS",
        message: "User deleted successfully",
      });
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  return (
    <>
      {deleteMutation.isLoading && <Loader />}
      {deleteUserInfo && (
        <Modal
          name={deleteUserInfo.name}
          onCancel={() => setDeleteUser(null)}
          onConfirm={() => {
            deleteMutation.mutate(deleteUserInfo._id);
            setDeleteUser(null);
          }}
        />
      )}
      {updateUserInfo && (
        <Modal
          isDelete={updateUserInfo.active ? "deactivate" : "activate"}
          name={updateUserInfo.name}
          onCancel={() => setUpdateUser(null)}
          onConfirm={() => {
            updateMutation.mutate({
              id: updateUserInfo._id,
              active: !updateUserInfo.active,
            });
            setUpdateUser(null);
          }}
        />
      )}
      {editUserInfo ? (
        <AddNewUser userInfo={editUserInfo} setUserInfo={setEditUser} />
      ) : (
        <div className="relative overflow-x-auto custom-scroll-x">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                {[
                  "User Id",
                  "Email",
                  "Name",
                  "Group",
                  "type",
                  "Created",
                  "Due to",
                  "Phone",
                  "Active",
                  "Actions",
                ].map((item, index) => {
                  return (
                    <th key={index} scope="col" className="px-6 py-3 text-center">
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                users?.map((item, index) => {
                  return (
                    <>
                      <tr
                        className={`border-b ${
                          new Date(item.due_date).getTime() >
                          new Date().getTime()
                            ? "bg-white"
                            : "bg-[#FDE99F]"
                        }`}
                      >
                        <td key={index} className="px-3 py-4">
                          {item._id}
                        </td>
                        <td key={index} className="px-3 py-4">
                          {item.email}
                        </td>
                        <td key={index} className="px-3 py-4">
                          {item.name}
                        </td>
                        <td
                          key={index}
                          className={`px-3 py-4 whitespace-nowrap ${
                            item.group ? "" : "bg-[#F9A86E]"
                          }`}
                        >
                          {item?.group?.name ?? "Not Assigned"}
                        </td>
                        <td key={index} className="px-3 py-4 capitalize">
                          {item.type.split("-").join(" ")}
                        </td>
                        <td
                          key={index}
                          className="px-3 py-4"
                          title={new Date(item.createdAt).toLocaleString()}
                        >
                          {new Date(item.createdAt).toLocaleDateString()}
                        </td>
                        <td
                          key={index}
                          className="px-3 py-4"
                          title={new Date(item.due_date).toLocaleString()}
                        >
                          <DueDate
                            item={item}
                            updateMutation={updateMutation}
                          />
                          {/* {new Date(item.due_date).toLocaleDateString()} */}
                        </td>
                        <td key={index} className="px-3 py-4">
                          {item.phone}
                        </td>
                        <td key={index} className="px-3 py-4">
                          <Button
                            onClick={() => {
                              setUpdateUser(item);
                            }}
                            className={`rounded-full border-0 ${
                              !item.active
                                ? "bg-primary hover:bg-primary"
                                : "bg-[#F24627] hover:bg-[#F24627]"
                            }`}
                          >
                            {item.active ? "Deactivate" : "Activate"}
                          </Button>
                        </td>
                        <td key={index} className="px-3 py-4 flex gap-3">
                          <Button
                            className={`rounded-full text-primary w-full`}
                            onClick={() => {
                              setEditUser(item);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className={`rounded-full border-0 bg-[#F24627] hover:bg-[#F24627] w-full`}
                            onClick={() => {
                              setDeleteUser(item);
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
          {isLoading && (
            <div className="bg-white border-b w-full text-center py-4 text-lg">
              Loading...
            </div>
          )}
        </div>
      )}
    </>
  );
}

export function DueDate({ item, updateMutation }) {
  const [inptType, setInptType] = useState("text");
  return (
    <>
      <div className="flex items-center">
        <Input
          disabled={inptType === "text"}
          disabledBG={false}
          className="border-0 bg-transparent w-max"
          type={inptType}
          value={new Date(item.due_date).toLocaleDateString()}
          format="dd/MM/yyyy"
          onChange={(e) => {
            updateMutation.mutate({
              id: item._id,
              due_date: e.target.value,
            });
            setInptType("text");
          }}
          id={"due-date" + item._id}
        />
        {/* edit icon svg */}
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer"
          onClick={() => {
            setInptType((pre) => (pre === "text" ? "date" : "text"));
          }}
        >
          <path
            d="M16.3 6.175L12.05 1.975L13.45 0.575C13.8333 0.191667 14.3043 0 14.863 0C15.4217 0 15.8923 0.191667 16.275 0.575L17.675 1.975C18.0583 2.35833 18.2583 2.821 18.275 3.363C18.2917 3.905 18.1083 4.36733 17.725 4.75L16.3 6.175ZM14.85 7.65L4.25 18.25H0V14L10.6 3.4L14.85 7.65Z"
            fill="gray"
          />
        </svg>
      </div>
    </>
  );
}
