import AddNewUser from "../section/owner/AddNewUser";
import UsersList from "../section/owner/UsersList";
import Button from "./Button";
import { useState } from "react";

export default function UserModule() {
  const [addNewUser, setAddNewUser] = useState(false);
  return (
    <>
      {addNewUser ? (
        <AddNewUser setAddNewUser={setAddNewUser} />
      ) : (
        <>
          <Button
            className="bg-primary hover:bg-primary text-white text-sm px-4 py-2 rounded-full ml-auto mr-10"
            onClick={() => {
              setAddNewUser(true);
            }}
          >
            Add New User
          </Button>
          <UsersList />
        </>
      )}
    </>
  );
}
