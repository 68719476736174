import { useNavigate } from "react-router-dom";
export default function BackIcon({ onClick }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31.667"
        height="31.667"
        viewBox="0 0 31.667 31.667"
        className="cursor-pointer"
        onClick={onClick}
      >
        <path
          id="chevron-circle-left-Light_1_"
          d="M895.59,578.922l-4.412,4.412,4.41,4.41a.834.834,0,0,1-1.18,1.178l-5-5a.834.834,0,0,1,0-1.178l5-5a.834.834,0,0,1,1.182,1.178Zm13.577,4.412A15.833,15.833,0,1,1,893.333,567.5,15.852,15.852,0,0,1,909.167,583.333Zm-1.667,0A14.167,14.167,0,1,0,893.333,597.5,14.183,14.183,0,0,0,907.5,583.333Z"
          transform="translate(-877.5 -567.5)"
          fill="#fff"
        />
      </svg>
    </>
  );
}
