import BarGraph from "./BarGraph";
import { useAppData } from "../context/appData";
import { useEffect, useState } from "react";
import SelectField from "./SelectField";
import { useUserLanguage } from "../context/userLanguage";
import { useAppTranslations } from "../api/translations";

export default function ViewsByMuscles() {
  const { data: genderClick } = useAppData("genderClick");
  const { language } = useUserLanguage();
  const { data: appTranslation, isLoading } = useAppTranslations();

  let date = new Date();
  const [viewsByMusle, setViewsByMusle] = useState({
    male: true,
    female: false,
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });

  const [data, setData] = useState({});

  useEffect(() => {
    let temp = {},
      temp1 = {};

    genderClick?.["male"]?.clicks?.filter((ele) => {
      let clickDate = null;
      if (ele.date.seconds) {
        clickDate = new Date(ele.date.nanoseconds + ele.date.seconds * 1000);
      } else {
        clickDate = new Date(ele.date);
      }
      if (
        clickDate.getFullYear() == viewsByMusle.year &&
        clickDate.getMonth() + 1 == viewsByMusle.month &&
        ele.muscle
      ) {
        temp = {
          ...temp,
          [ele.muscle]: temp[ele.muscle] ? temp[ele.muscle] + 1 : 1,
        };
        temp1 = {
          ...temp1,
          [ele.muscle]: 0,
        };
      }
    });
    genderClick?.["female"]?.clicks?.filter((ele) => {
      let clickDate = null;
      if (ele.date.seconds) {
        clickDate = new Date(ele.date.nanoseconds + ele.date.seconds * 1000);
      } else {
        clickDate = new Date(ele.date);
      }
      if (
        clickDate.getFullYear() == viewsByMusle.year &&
        clickDate.getMonth() + 1 == viewsByMusle.month &&
        ele.muscle
      ) {
        temp1 = {
          ...temp1,
          [ele.muscle]: temp1[ele.muscle] ? temp1[ele.muscle] + 1 : 1,
        };
        temp = {
          ...temp,
          [ele.muscle]: temp[ele.muscle] ? temp[ele.muscle] : 1,
        };
      }
    });
    setData((pre) => ({ ...pre, male: temp, female: temp1 }));
  }, [viewsByMusle]);
  return (
    <>
      <div className="w-full px-6 pb-6">
        <p className="text-white text-lg font-medium">
          {appTranslation?.translations?.adminPage?.musclesViews?.[language] ??
            "Muscles Views"}
        </p>
        <div className="flex items-center justify-start gap-4 mt-2">
          <SelectField
            className="w-40"
            value={viewsByMusle.year}
            setValue={(value) => {
              setViewsByMusle((pre) => ({
                ...pre,
                year: Number(value),
              }));
            }}
          >
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </SelectField>
          <SelectField
            className="w-40"
            value={viewsByMusle.month}
            setValue={(value) => {
              setViewsByMusle((pre) => ({
                ...pre,
                month: Number(value),
              }));
            }}
          >
            {[...Array(12)].map((_, i) => (
              <option value={i + 1}>{i + 1}</option>
            ))}
          </SelectField>
        </div>
      </div>
      <div className="w-full px-6 pb-6">
        <BarGraph male={data.male} female={data.female} />
      </div>
    </>
  );
}
