import {
  useGroups,
  addGroup,
  deleteGroup,
  removeMemberFromGroup,
  addMemberToGroup,
} from "../../../api/groups";
import DropDownIcon from "../../../assets/DropdownIcon";
import { useState } from "react";
import Button from "../../../components/Button";
import { useNotification } from "../../../AppNotification/NotificationProvider";
import Loader from "../../../components/Loader";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../index";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import SelectField from "../../../components/SelectField";
import { useUsers } from "../../../api/userAuth";

export default function GroupsModule() {
  const notification = useNotification();
  const { data: groups, isLoading, isError } = useGroups();
  const {
    data: users,
    isLoading: usersLoading,
    isError: usersError,
  } = useUsers();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [addNewGroup, setAddNewGroup] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [deleteGroupDetails, setDeleteGroup] = useState();
  const addGroupMutation = useMutation(addGroup, {
    onSuccess: () => {
      setGroupName("");
      notification({
        type: "SUCCESS",
        message: "Group added successfully",
      });
      queryClient.invalidateQueries(["gropsData"]);
      queryClient.invalidateQueries(["usersData"]);
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  const deleteGroupMutation = useMutation(deleteGroup, {
    onSuccess: () => {
      notification({
        type: "SUCCESS",
        message: "Group deleted successfully",
      });
      queryClient.invalidateQueries(["gropsData"]);
      queryClient.invalidateQueries(["usersData"]);
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  const removeMemberMutation = useMutation(removeMemberFromGroup, {
    onSuccess: () => {
      notification({
        type: "SUCCESS",
        message: "Member removed successfully",
      });
      queryClient.invalidateQueries(["gropsData"]);
      queryClient.invalidateQueries(["usersData"]);
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  const addMemberToGroupMutation = useMutation(addMemberToGroup, {
    onSuccess: () => {
      notification({
        type: "SUCCESS",
        message: "Member added successfully",
      });
      queryClient.invalidateQueries(["gropsData"]);
      queryClient.invalidateQueries(["usersData"]);
    },
    onError: (err) => {
      notification({
        type: "ERROR",
        message: err.response.data,
      });
    },
  });

  const handleAddGroup = () => {
    if (groupName === "") {
      notification({
        type: "ERROR",
        message: "Group name cannot be empty",
      });
      return;
    }
    addGroupMutation.mutate({
      name: groupName,
    });
  };

  return (
    <>
      {deleteGroupDetails && (
        <Modal
          name={deleteGroupDetails.name}
          onConfirm={() => {
            deleteGroupMutation.mutate(deleteGroupDetails._id);
            setDeleteGroup(null);
          }}
          onCancel={() => {
            setDeleteGroup(null);
          }}
        />
      )}
      {(addGroupMutation.isLoading ||
        deleteGroupMutation.isLoading ||
        removeMemberMutation.isLoading ||
        addMemberToGroupMutation.isLoading) && <Loader />}
      {isLoading ? (
        <h1 className="text-white">Loading...</h1>
      ) : (
        <div className="flex">
          <div className="w-max flex flex-col gap-4 h-[70vh] overflow-y-auto custom-scroll">
            {groups?.map((groupDetails, index) => (
              <div key={index} className="flex flex-col gap-2 w-max">
                <div className="flex items-center gap-3 w-max">
                  <div
                    className="flex items-center gap-3 cursor-pointer"
                    onClick={() => {
                      setSelectedGroup((pre) => (pre === index ? null : index));
                      setAddNewGroup(null);
                    }}
                  >
                    <DropDownIcon
                      className={`transition-all duration-500 ${
                        selectedGroup === index ? "rotate-180" : ""
                      }`}
                    />
                    <h1 className="text-white text-xl font-bold">
                      {groupDetails.name}
                    </h1>
                    <p className="text-white text-sm">
                      ({groupDetails.members.length} users)
                    </p>
                  </div>
                  <Button
                    className="rounded-full border-0 bg-[#F24627] hover:bg-[#F24627]"
                    onClick={() => {
                      setDeleteGroup(groupDetails);
                    }}
                  >
                    Delete
                  </Button>
                </div>
                {selectedGroup === index ? (
                  groupDetails.members.length > 0 ? (
                    groupDetails.members.map((userDetail, index2) => (
                      <div
                        key={index2}
                        className="flex gap-4 items-center ml-4 transition-all duration-500"
                      >
                        <p
                          className="text-white text-sm"
                          title={userDetail._id}
                        >
                          {userDetail._id.slice(0, 5)}...
                        </p>
                        <p className="text-white capitalize">
                          {userDetail.name}
                        </p>
                        <Button
                          className="rounded-full border-0 bg-[#DB0163] hover:bg-[#DB0163]"
                          onClick={() => {
                            removeMemberMutation.mutate({
                              groupId: groupDetails._id,
                              memberId: userDetail._id,
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    ))
                  ) : (
                    <p className="text-white text-sm ml-4">No members</p>
                  )
                ) : (
                  ""
                )}
                {addNewGroup === null && selectedGroup === index ? (
                  <Button
                    className="rounded-full border-0 bg-primary hover:bg-primary ml-4 mt-4"
                    onClick={() => {
                      setAddNewGroup((pre) => (pre === index ? null : index));
                    }}
                  >
                    Add existing user
                  </Button>
                ) : (
                  ""
                )}
                {addNewGroup === index && (
                  <SelectField
                    className="w-[250px]"
                    setValue={(value) => {
                      addMemberToGroupMutation.mutate({
                        groupId: groupDetails._id,
                        memberId: value,
                      });
                      setAddNewGroup(null);
                    }}
                  >
                    {users
                      ?.filter((user) => !user.group)
                      ?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </SelectField>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center gap-4 ml-auto mr-20 self-start">
            <Input
              type="text"
              placeholder="Group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <Button
              className="rounded-full border-0 bg-primary hover:bg-primary"
              onClick={handleAddGroup}
            >
              Add group
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
