export default function CallIcon({ className, ...props }) {
  return (
    <svg
      id="Group_35"
      data-name="Group 35"
      xmlns="http://www.w3.org/2000/svg"
      width="15.338"
      height="15.337"
      viewBox="0 0 15.338 15.337"
      className={`${className ?? ""}`}
      {...props}
    >
      <path
        id="Path_336"
        data-name="Path 336"
        d="M15.935,12.85l-3.1-2.07a.906.906,0,0,0-1.213.2l-.9,1.163a.386.386,0,0,1-.5.1l-.172-.095a10.4,10.4,0,0,1-2.709-2.128A10.438,10.438,0,0,1,5.209,7.311l-.094-.172a.387.387,0,0,1,.1-.5l1.162-.9a.907.907,0,0,0,.2-1.213l-2.07-3.1A.9.9,0,0,0,3.29,1.147l-1.3.78A1.831,1.831,0,0,0,1.157,3.01c-.467,1.7-.116,4.643,4.214,8.973,3.445,3.444,6.009,4.371,7.771,4.371a4.528,4.528,0,0,0,1.2-.157,1.829,1.829,0,0,0,1.084-.835l.781-1.3A.9.9,0,0,0,15.935,12.85Zm-.167.953-.779,1.3a1.321,1.321,0,0,1-.78.6c-1.572.432-4.319.074-8.477-4.083S1.219,4.718,1.65,3.146a1.323,1.323,0,0,1,.6-.781l1.3-.779a.391.391,0,0,1,.527.119L5.205,3.393l.944,1.416a.393.393,0,0,1-.085.527l-1.162.9a.9.9,0,0,0-.237,1.148l.092.168a10.843,10.843,0,0,0,2.215,2.827A10.854,10.854,0,0,0,9.8,12.6l.168.093a.9.9,0,0,0,1.148-.237l.9-1.162a.394.394,0,0,1,.527-.085l3.1,2.07A.391.391,0,0,1,15.768,13.8Z"
        transform="translate(-1 -1.018)"
        fill="#00ffdc"
      />
      <path
        id="Path_337"
        data-name="Path 337"
        d="M282.884,69.835a4.35,4.35,0,0,1,4.345,4.345.256.256,0,1,0,.511,0,4.861,4.861,0,0,0-4.856-4.856.256.256,0,0,0,0,.511Z"
        transform="translate(-274.193 -67.278)"
        fill="#00ffdc"
      />
      <path
        id="Path_338"
        data-name="Path 338"
        d="M282.884,121.035a2.815,2.815,0,0,1,2.812,2.811.256.256,0,1,0,.511,0,3.326,3.326,0,0,0-3.323-3.323.256.256,0,0,0,0,.511Z"
        transform="translate(-274.193 -116.945)"
        fill="#00ffdc"
      />
      <path
        id="Path_339"
        data-name="Path 339"
        d="M282.884,172.235a1.28,1.28,0,0,1,1.278,1.278.256.256,0,1,0,.511,0,1.791,1.791,0,0,0-1.789-1.789.256.256,0,0,0,0,.511Z"
        transform="translate(-274.193 -166.611)"
        fill="#00ffdc"
      />
    </svg>
  );
}
