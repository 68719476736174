export default function Button({ className, children, ...props }) {
  return (
    <button
      className={`text-white text-base border-primary hover:bg-[#91fa4b53] border w-fit px-5 py-[3px] rounded-md capitalize ${
        className ?? ""
      }`}
      {...props}
    >
      {children}
    </button>
  );
}
