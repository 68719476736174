import AxiosInstance from "./axios";
import { useQuery } from "@tanstack/react-query";

export async function getAppTranslations() {
  try {
    const response = await AxiosInstance.get("master");
    if (response.data) {
      localStorage.setItem("translations", JSON.stringify(response.data));
    }
    return response.data;
  } catch (error) {
    return new Error(error);
  }
}

export function useAppTranslations() {
  const { data, isLoading, error } = useQuery(
    ["translations"],
    getAppTranslations
  );
  const translations = data
    ? data
    : JSON.parse(localStorage.getItem("translations"));
  return { data: translations, isLoading: data ? false : isLoading, error };
}
